import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import classesC from "../../components/Componet.module.css";
import {
  googleDriveList,
  googleDriveListProcess,
  oneDriveList,
  oneDriveListProcess,
  googleDriveListNextPage,
} from "../../sevices";
import classes from "./file.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ListView(props) {
  const { token, accountType, modalClose } = props;
  const [checked, setChecked] = useState([]);
  const [checkedSelected, setCheckedSelected] = useState([]);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  // setChecked([value]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    const selectArray = data.filter((res) => newChecked.includes(res.id));
    setCheckedSelected(selectArray);
  };

  const onSubmit = async () => {
    if (checkedSelected.length === 0) {
      toast.error("Please select file or folder");
      return;
    }
    if (accountType === "GOOGLE") {
      let body = {
        user_id: localStorage.getItem("userId"),
        file_ids_to_process: checkedSelected,
      };
      let result = await googleDriveListProcess(body, token);

      if (result) props.modalClose();
      toast.success("File uploaded successfully", {
        position: "top-right",
      });
    } else if (accountType === "MICROSOFT") {
      let body = {
        user_id: localStorage.getItem("userId"),
        file_ids_to_process: checkedSelected,
      };

      let result = await oneDriveListProcess(body, token);
      modalClose();
    }
  };

  useEffect(() => {
    if (accountType === "GOOGLE") {
      (async () => {
        let result = await googleDriveList(token);
        setData(result.data.data);
        setNextPageToken(result.data.next_page_token);
      })();
    } else if (accountType === "MICROSOFT") {
      (async () => {
        let result = await oneDriveList(token);
        setData(result?.data?.data);
      })();
    }
  }, [token, accountType]);

  const nextPage = async () => {
    let result = await googleDriveListNextPage(props.token, nextPageToken);
    if (result?.data?.next_page_token) {
      setNextPageToken(result.data.next_page_token);
      setData(result.data.data);
    }
  };

  const prePage = async () => {
    let result = await googleDriveListNextPage(props.token, nextPageToken);
    setData(result.data.data);
    setNextPageToken(result.data.next_page_token);
  };

  return (
    <div className={classesC.bg}>
      <h2 className={classesC.heading}>Upload File</h2>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data ? (
          data.map((value, index) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <ListItem key={value.name} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.name}`} />
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <h1>Loading...</h1>
        )}
        {accountType === "GOOGLE" && (
          <div className={`${classesC.buttonDiv} ${classes.buttonDiv}`}>
            <ArrowBackIosIcon onClick={prePage} fontSize="small" />
            <ArrowForwardIosIcon onClick={nextPage} fontSize="small" />
          </div>
        )}

        <div className={`${classesC.buttonDiv} ${classes.buttonDiv}`}>
          <button type="button" className="btn" onClick={onSubmit}>
            Upload file or folder
          </button>
          <button type="button" className="btn" onClick={modalClose}>
            Cancel
          </button>
        </div>
      </List>
    </div>
  );
}
