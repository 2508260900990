import React, { useState, useEffect } from "react";
import { ListAllUserStatus } from "../../sevices";
import upload from "../../assets/Upload.svg";
import classes from "../file/file.module.css";
import CreateDatabase from "./CreateDatabase";
import DialogModal from "../../components/DialogModal";
// import ListComponent from "../../components/ListComponent";
import { toast } from "react-toastify";
import CustomizedTables from "../../components/CustomizedTables";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Tooltip } from "@mui/material";
function Database() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  const getDatabaseData = async () => {
    try {
      let result = await ListAllUserStatus(localStorage.getItem("userId"));
      if (result.message) {
        toast.error(result.message, {
          position: "top-right",
        });
      }
      let results = result?.data?.data?.databases;
      setData(results ? results : []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      getDatabaseData();
    })();
  }, []);

  return (
    <div className={"m-2"}>
      <div className={classes.file}>
        <h1>Database Manager</h1>
      </div>
      <div className="text-right">
        <Tooltip title="Refresh">
          <IconButton onClick={() => getDatabaseData()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <button className="btn" onClick={modalOpen}>
          <img src={upload} title="upload" alt="upload" /> Configure Database
        </button>
      </div>
      {/* <ListComponent data={data} type={"database"} /> */}
      <CustomizedTables data={data} type={"database"} />
      <DialogModal
        open={open}
        modalClose={modalClose}
        contentComponent={<CreateDatabase modalClose={modalClose} />}
      />
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Database;
