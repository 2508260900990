import React, { useState } from "react";
import classes from "./q2ml.module.css";
import { Checkbox, OutlinedInput, TextField } from "@mui/material";
import DropDown from "./DropDown";
import { GETSubSample } from "../../sevices/q2mlservice";

function Subsample(props) {
  const [data, setData] = useState();
  const fetchResult = async (value) => {
    let body = {
      subsampling_strategy: props?.samplingSteategyValue?.value,
      subsample_ratio: Number(value),
      query: props.query,
      connection_name: props.connection_name,
    };
    try {
      let result = await GETSubSample(body);
      setData(result.data);
    } catch (error) {}
  };
  return (
    <div>
      <div className={classes.mb2}>
        <Checkbox
          // defaultChecked
          sx={{ padding: "0" }}
          //   checked={props.trainedModal}
          onChange={(e) => {
            props.onChangeTrainModal(e.target.checked);
          }}
        />
        <label>Enable data sampling</label>
      </div>
      {props.trainedModal && (
        <>
          <div className={`${classes.grid2} ${classes.mb2}`}>
            <DropDown
              onChange={props.onChange}
              data={props.data}
              title={"Select Sub sampling strategy"}
              value={props.samplingSteategyValue}
            />
            <div>
              <p>Select Sub sampling percentage</p>
              <OutlinedInput
                fullWidth
                size="small"
                // label="Select Sub sampling percentage"
                // id="outlined-basic"
                // variant="outlined"
                type="number"
                max="100"
                onChange={async (e) => {
                  await props.onChangeSamplingPercentage(e.target.value);
                  fetchResult(e.target.value);
                }}
              />
            </div>
          </div>
          {data && (
            <div>
              <p>Result</p>
              <p>
                Size of original data:
                <span className={classes.bold}>{data?.original_size} rows</span>
              </p>
              <p>
                Size of subsample data:
                <span className={classes.bold}>
                  {data?.subsample_size} rows
                </span>
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Subsample;
