import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ChatButton from "../../components/ChatButton";
import DatabaseCreateForm from "../../components/DatabaseCreateForm";
import classes from "../style.module.css";
export default function UpdateQueqe(props) {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries(formData.entries());
            // const email = formJson.email;
            // handleClose();
          },
        }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DatabaseCreateForm title={props.title} />
          {/* {props.title == "Update" && ( */}
          <div className={props.title === "Update" ? "" : classes.inActive}>
            <ChatButton title="Update" buttonClick={props.handleClose} />
            <ChatButton title="Close" buttonClick={props.handleClose} />
          </div>
          {/* )} */}
          {/* <CreateDatabase title={"Update Database"} buttonTitle={"Update"} /> */}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
