import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Copyright from "./Copyright";
import FormInput from "./FormInput";
import * as yup from "yup";
import classes from "./Login.module.css";
import objectImg from "../assets/OBJECTS.svg";
import datalen from "../assets/datalens.svg";
import mail from "../assets/mail.svg";
import { useFormik } from "formik";
import { SignIn } from "../sevices";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

/**
@author      : Pankaj Kumar
@date        : 2023-02-05
@description : Login page
*/

export default function Login() {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  // Validation form
  const validationSchema = yup.object({
    email_id: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      // .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });
  // Creare instance of formik
  const formik = useFormik({
    // Initialize value
    initialValues: {
      email_id: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true); // Start loading
      try {
        const result = await SignIn(values);
        setLoading(false); // Stop loading
        if (result === 404)
          toast.error("Something went wrong", {
            position: "top-right",
          });
        if (result.data.status === false) {
          toast.error(result.data.message, {
            position: "top-right",
          });
        } else {
          if (result?.data) {
            toast.success("Login Successful!", {
              position: "top-right",
            });

            localStorage.setItem("token", result?.data?.access_token);
            localStorage.setItem("userId", result?.data?.data?.user_id);
            localStorage.setItem(
              "userName",
              result?.data?.data?.user_full_name
            );

            setTimeout(() => {
              // window.location.reload();
              window.location.assign("/home");
            });
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false); // Stop loading
      }
    },
  });

  return (
    <Grid container component="main" sx={{ height: "99vh" }}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.main}>
        <img
          src={objectImg}
          className={classes.logo}
          alt="logo"
          style={{ width: "44%" }}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.login}>
          <Box>
            <img src={datalen} alt="datalens" />
            {/* <p className={classes.accelerated}> Accelerator by Konverge.AI</p> */}
            <p className={classes.accelerated}> Accelerator for Gen AI</p>
            {/* <h1 className={classes.heading}>Welcome to DataLens!</h1> */}

            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ display: "grid", marginTop: "2rem" }}
            >
              <FormInput
                formik={formik}
                para={{
                  label: "Email",
                  placeholder: "Enter Email",
                  name: "email_id",
                  required: true,
                  icon: mail,
                }}
              />
              <FormInput
                formik={formik}
                para={{
                  label: "Password",
                  placeholder: "Enter Password",
                  name: "password",
                  required: true,
                  icon: "",
                  password: true,
                }}
              />
              <div className={classes.remember}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Remember me"
                />
                <Link to="/forgot-password">Forgot Password</Link>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  margin: "2rem auto",
                  width: "133px",
                  height: "46px",
                  background: "#165DB9 0% 0% no-repeat padding-box",
                  borderRadius: "15px",
                }}
                endIcon={
                  loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
                disabled={loading}
              >
                Submit
              </Button>
              <Grid container>
                <Grid item>
                  <Link to="/sign-up">
                    <span>Don't have an account?</span> Sign Up
                  </Link>
                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
              {/* <ToastContainer /> */}
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
