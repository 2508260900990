import { NavLink } from "react-router-dom";
import { ReactComponent as File } from "../assets/FileLined.svg";
import { ReactComponent as Database } from "../assets/DatabaseLined.svg";
import { ReactComponent as MetaData } from "../assets/Metadata.svg";
import { ReactComponent as Chatbot } from "../assets/Chatbot.svg";
import HomeIcon from "@mui/icons-material/Home";
import classes from "./layout.module.css";

import Q2MLicon from "@mui/icons-material/AcUnit";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Side bar Navigate URL and highlight
*/

function Sidebar() {
  return (
    <div className={classes.side_bar_nav}>
      <NavLink
        id={classes.file}
        title="Home"
        to="/"
        className={(
          { isActive } // Checking and Highlight link
        ) => (isActive ? `${classes.active} ${classes.activeFile}` : classes.a)}
      >
        <div>
          <HomeIcon />
        </div>
      </NavLink>
      <NavLink
        id={classes.file}
        title="File Manager"
        to="file"
        className={(
          { isActive } // Checking and Highlight link
        ) => (isActive ? `${classes.active} ${classes.activeFile}` : classes.a)}
      >
        <div>
          <File />
        </div>
      </NavLink>
      <NavLink
        id={classes.database}
        to="database"
        title="Database Manager"
        className={({ isActive }) =>
          isActive ? `${classes.active} ${classes.activeDatabase}` : classes.a
        }
      >
        <div>
          <Database />
        </div>
        {/* <span> Database</span> */}
      </NavLink>
      <NavLink
        id={classes.metadata}
        to="metadata"
        title="Metadata Manager"
        className={({ isActive }) =>
          isActive ? `${classes.active} ${classes.activeMetadata}` : classes.a
        }
      >
        <div>
          <MetaData />
        </div>
        {/* <span> Meta data</span> */}
      </NavLink>
      <NavLink
        // exact
        title="Chat Manager"
        id={classes.chatbot}
        to={"chat/file/?" || "chat/database/?"}
        className={({ isActive }) =>
          isActive ? `${classes.active} ${classes.activeChatbot}` : classes.a
        }
      >
        <div>
          <Chatbot />
        </div>
        {/* <span> Chatbot</span> */}
      </NavLink>
      <NavLink
        id={classes.file}
        title="Q2ML Manager"
        to="q2ml"
        className={(
          { isActive } // Checking and Highlight link
        ) => (isActive ? `${classes.active} ${classes.activeFile}` : classes.a)}
      >
        <div>
          <AutoGraphIcon />
        </div>
      </NavLink>
    </div>
  );
}

export default Sidebar;
