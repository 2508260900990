// Third party
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, CssBaseline, Paper, Box, Grid } from "@mui/material";
// icon
import mail from "../assets/mail.svg";
import datalen from "../assets/datalens.svg";
import userIcon from "../assets/Iconuser.svg";
import SuccessIcon from "../assets/Success.svg";

import objectImg from "../assets/OBJECTS.svg";
import department from "../assets/department.svg";
import organization from "../assets/organization.svg";
// import from local
import FormInput from "./FormInput";
import { SignUp } from "../sevices";
import classes from "./Login.module.css";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

/**
@author      : Pankaj Kumar
@date        : 2023-02-05
@description : Sign up page
*/

export default function Signup() {
  const [user, setUser] = useState(true);
  const [loading, setLoading] = useState(false);
  // Validation form
  const validationSchema = yup.object({
    first_name: yup
      .string("Enter your first name")
      .required(" Name is required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed"),
    last_name: yup
      .string("Enter your last name")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed"),
    // .required("Last name is required"),
    email_id: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')

      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/.,])(?!.*\s).{8,}$/,
        "Password should contain a capital letter, a small letter, a number and a special character."
      )

      // .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirm_password: yup
      .string("Enter your password")
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),

    organization: yup
      .string("Enter your organization")
      .required("Organization is required")
      .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed"),
    department: yup
      .string("Enter your department")
      // .min(8, "Password should be of minimum 8 characters length")
      .required("Department is required"),
  });
  // Creare instance of formik
  const formik = useFormik({
    // Initialize value
    initialValues: {
      first_name: "",
      last_name: "",
      email_id: "",
      password: "",
      confirm_password: "",
      department: "",
      organization: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true); // Start loading
      try {
        const result = await SignUp(values);
        setLoading(false); // Stop loading
        if (result === 404)
          toast.error("Something went wrong", {
            position: "top-right",
          });
        if (result.data.status === true) {
          setUser(false);
          // navigate("/sign-up");
        } else {
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false); // Stop loading
        toast.error("Something went wrong", {
          position: "top-right",
        });
      }
    },
  });

  return (
    <Grid container component="main" sx={{ height: "99vh" }}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.main}>
        <img src={objectImg} className={classes.logo} alt="logo" />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.login}>
          <Box>
            <img src={datalen} alt="datalens" />
            <p className={classes.accelerated}> Accelerator by Konverge.AI</p>
            {/* <h1 className={classes.heading}>Welcome to DataLens!</h1> */}
            <>
              {user ? (
                <Box
                  component="form"
                  noValidate
                  onSubmit={formik.handleSubmit}
                  sx={{ display: "grid", marginTop: "2rem" }}
                >
                  <FormInput
                    formik={formik}
                    para={{
                      label: "First name*",
                      placeholder: "Enter name",
                      name: "first_name",
                      required: true,
                      icon: userIcon,
                    }}
                  />
                  <FormInput
                    formik={formik}
                    para={{
                      label: "Last name",
                      placeholder: "Enter last name",
                      name: "last_name",
                      required: true,
                      icon: userIcon,
                    }}
                  />
                  <FormInput
                    formik={formik}
                    para={{
                      label: "Email*",
                      placeholder: "Enter email",
                      name: "email_id",
                      required: true,
                      icon: mail,
                    }}
                  />

                  <FormInput
                    formik={formik}
                    para={{
                      label: "Organization*",
                      placeholder: "Enter organization",
                      name: "organization",
                      required: true,
                      icon: organization,
                    }}
                  />
                  <FormInput
                    formik={formik}
                    para={{
                      label: "Department",
                      placeholder: "Enter department",
                      name: "department",
                      // required: true,
                      icon: department,
                      select: true,
                    }}
                  />
                  <FormInput
                    formik={formik}
                    para={{
                      label: "Password*",
                      placeholder: "Enter password",
                      name: "password",
                      required: true,
                      icon: "",
                      password: true,
                    }}
                  />
                  <FormInput
                    formik={formik}
                    para={{
                      label: "Confirm password*",
                      placeholder: "Enter confirm password",
                      name: "confirm_password",
                      required: true,
                      icon: "",
                      password: true,
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      margin: "1.5rem auto",
                      width: "233px",
                      height: "46px",
                      background: "#165DB9 0% 0% no-repeat padding-box",
                      borderRadius: "15px",
                    }}
                    endIcon={
                      loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                    disabled={loading}
                  >
                    Create account
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link to="/login">
                        <span> Have an account?</span> Log in
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ display: "grid" }}>
                  <h3 className={classes.account}>Account Created</h3>
                  <img
                    className={classes.accountImg}
                    src={SuccessIcon}
                    alt="Success"
                  />
                  <h6>Please Login with a new credential</h6>
                  <Link to="/login">
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{
                        margin: "1.5rem auto",
                        width: "233px",
                        height: "46px",
                        background: "#165DB9 0% 0% no-repeat padding-box",
                        borderRadius: "15px",
                      }}
                    >
                      Login
                    </Button>
                  </Link>
                </Box>
              )}
            </>
          </Box>
        </div>
      </Grid>
      {/* <ToastContainer /> */}
    </Grid>
  );
}
