import React, { useState } from "react";
import { Box, CircularProgress, Slider } from "@mui/material";
import classes from "./q2ml.module.css";
import Q2MLDataTable from "./Q2MLDataTable";
import DropDown from "./DropDown";
import Subparameters from "./Subparameters";
import Hyperparameter from "./Hyperparameter";
import { toast } from "react-toastify";
import { VIEWTRAINDS, trainSubmit } from "../../sevices/q2mlservice";
import Subsample from "./Subsample";
import DialogModal from "../../components/DialogModal";
import TimeseriesChart from "./TimeseriesChart";

function Q2mlDatasets(props) {
  const [open, setOpen] = React.useState(false);
  const [trainingValue, setTrainingValue] = useState({});
  const [modelValue, setModelValue] = useState({});
  const [frequencyValue, setFrequencyValue] = useState({});
  const [timeSeriesValue, setTimeSeriesValue] = useState({});
  const [dateTimeValue, setDateTimeValue] = useState({});
  const [dataSefFeature, setDataSetFeature] = React.useState();
  const [modelType, setModalType] = React.useState();
  const [dataSetTarget, setDataSetTarget] = React.useState();
  const [hyperData, setHyperData] = useState();
  const [trainModelData, setTrainModelData] = useState();
  const [loadingApi, setLoadingApi] = useState(false);
  const [trainedModal, setTrainedModel] = useState(false);
  const [samplingSteategyValue, setSamplingSteategyValue] = useState();
  const [samplingRatio, setSamplingRatio] = useState();
  const [seasonilityValue, setSeasonilityValue] = useState();
  const [seasonilityMode, setSeasonilityMode] = useState();
  const [fourierSlider, setfourierSlider] = useState();
  const [viewDataChart, setViewDataChart] = useState();
  const [modelTypeValue, setModelTypeValue] = useState();

  const modalClose = () => {
    setOpen(false);
  };
  const onChangeTraining = (list) => {
    setTrainingValue(list);
    // need to clear
    setModelValue({});
    setTrainModelData();
    setModelTypeValue();
    // setFrequencyValue({});
    // setTimeSeriesValue({});
    // setDateTimeValue({});
    // setDataSetFeature();
    // setModalType();
    // setDataSetTarget();
    // // setHyperData();
    // setTrainedModel();
    // setSamplingSteategyValue();
    // setSamplingRatio();
    // setSeasonilityValue();
    // setSeasonilityMode();
    // setfourierSlider();
  };
  const clearAll = async () => {
    setModelValue({});
    setTrainModelData();
    return;
    setTrainingValue();
    // need to clear
    // setModelValue({});
    setTrainModelData();
    setFrequencyValue({});
    setTimeSeriesValue({});
    setDateTimeValue({});
    setDataSetFeature();
    setModalType();
    setDataSetTarget();
    // setHyperData();
    setTrainedModel();
    setSamplingSteategyValue();
    setSamplingRatio();
    setSeasonilityValue();
    setSeasonilityMode();
    setfourierSlider();
  };

  const onChangeModelList = (list) => {
    if (list?.type) {
      setModalType(list?.type);
    }
    setModelValue(list);
    // need to clear
    // setFrequencyValue({});
    // setTimeSeriesValue({});
    // setDateTimeValue({});
    // setDataSetFeature();
    // setDataSetTarget();
    // // setHyperData();
    // // setTrainModelData();
    // // setTrainedModel();
    // // setSamplingSteategyValue();
    // // setSamplingRatio();
    // setSeasonilityValue();
    // setSeasonilityMode();
    // setfourierSlider();
  };
  const onChangeTimeSeriesList = (list) => {
    setTimeSeriesValue(list);
    // setTrainModelData();
  };
  const onChangeSeasonilityType = (list) => {
    setSeasonilityValue(list);
  };
  const onChangeFrequency = (list) => {
    setFrequencyValue(list);
  };
  const onChangeDateTime = (list) => {
    setDateTimeValue(list);
  };
  const onChangeSeasonilityMode = (list) => {
    setSeasonilityMode(list);
  };

  const onChangeFeacture = (id) => {
    setDataSetFeature(id);
  };
  const onChangeTarget = (id) => {
    setDataSetTarget(id);
  };
  const onChangeSamplingSteategy = (list) => {
    setSamplingSteategyValue(list);
  };
  const onChangeTrainModal = (value) => {
    setTrainedModel(value);
  };
  const onChangeSamplingPercentage = (value) => {
    setSamplingRatio(value);
  };
  const onChangeModelType = (value) => {
    setModelTypeValue(value);
    const modelValue = "";
    setModelValue({});
    // setModelValue();
  };

  const supervisedAndUnsupervised = (value) => {
    // const modifiedData = value?.reduce((acc, curr) => {
    //   let getKey = Object.keys(curr);
    //   if (
    //     getKey.length > 0 &&
    //     ["supervised", "unsupervised"].includes(getKey?.[0])
    //   ) {
    //     acc.push({
    //       label: getKey?.[0].toUpperCase(),
    //       value: null,
    //       isDisabled: true,
    //     });
    //     acc.push(...curr[getKey[0]]);
    //   } else {
    //     acc.push(curr);
    //   }
    //   return acc;
    // }, []);
    return value;
  };

  const changeHyperData = async (temp) => {
    let a = {};
    temp?.forEach((element) => {
      if (element) {
        let keyOfNewProperty = element.new;
        if (keyOfNewProperty) {
          let keyVal = Object.keys(keyOfNewProperty)[0];
          a[keyVal] = element?.new[keyVal];
        }
      }
    });

    setHyperData(a);
  };

  const trainModel = async () => {
    let dataSefFeatureValue = dataSefFeature?.map((res) => res.value);
    let dataSefFeatureTarget = dataSetTarget?.map((res) => res.value);
    if (trainedModal) {
      if (samplingSteategyValue === undefined || samplingRatio === undefined) {
        toast.error("Sub sampling is required", {
          position: "top-right",
        });
        return false;
      }
    }

    setLoadingApi(true);
    if (trainingValue.value === "classification_models") {
      let body = {
        query: props.sqlQuery,
        table: "",
        features: dataSefFeatureValue,
        target: dataSefFeatureTarget,
        model: modelValue.value,
        hyperparams: hyperData ? hyperData : {},
        connection_name: props.selectedDB,
        subsample: trainedModal,
        subsampling_strategy: trainedModal
          ? samplingSteategyValue.value
          : "None",
        subsample_ratio: trainedModal ? Number(samplingRatio) : 0,
        anomaly_detection_type: modelType,
      };

      let result = await trainSubmit(body, "classification");
      setLoadingApi(false);
      if (result?.code === "ERR_BAD_REQUEST") {
        setLoadingApi(false);
        toast.error(result.code, {
          position: "top-right",
        });
      }
      if (result?.data) {
        setLoadingApi(false);
        toast.success("Train model successfully", {
          position: "top-right",
        });
        setTrainModelData(result.data);
      } else {
        toast.error(result.response.data.error, {
          position: "top-right",
        });
      }
    }
    if (trainingValue.value === "regression_models") {
      let body = {
        query: props.sqlQuery,
        table: "",
        features: dataSefFeatureValue,
        target: dataSefFeatureTarget,
        model: modelValue.value,
        hyperparams: hyperData ? hyperData : {},
        connection_name: props.selectedDB,
        subsample: trainedModal,
        subsampling_strategy: trainedModal
          ? samplingSteategyValue.value
          : "None",
        subsample_ratio: trainedModal ? Number(samplingRatio) : 0,
        anomaly_detection_type: modelType,
      };
      let result = await trainSubmit(body, "regression");
      setLoadingApi(false);
      if (result?.code === "ERR_BAD_REQUEST") {
        setLoadingApi(false);
        toast.error(result.code, {
          position: "top-right",
        });
      }
      if (result?.data) {
        setLoadingApi(false);
        toast.success("Train model successfully", {
          position: "top-right",
        });

        setTrainModelData(result.data);
      } else {
        toast.error(result.response.data.error, {
          position: "top-right",
        });
      }
    }
    if (trainingValue.value === "time_series_models") {
      let body = {
        query: props.sqlQuery,
        table: "",
        features:
          timeSeriesValue?.value === "multivariate" &&
          modelValue.value === "prophet"
            ? dataSefFeatureValue
            : [""],
        target: dataSefFeatureTarget,
        model: modelValue.value,
        datetime: [dateTimeValue.value],
        timeseries_type: timeSeriesValue.value,
        seasonality_type: seasonilityValue ? seasonilityValue.value : null,
        frequency: frequencyValue.value,
        connection_name: props.selectedDB,
        subsample: trainedModal,
        seasonality_mode: seasonilityMode ? seasonilityMode.value : null,
        fourier_order: fourierSlider ? parseInt(fourierSlider) : null,
        subsampling_strategy: trainedModal ? samplingSteategyValue.value : null,
        subsample_ratio: trainedModal ? Number(samplingRatio) : 0,
      };

      let result = await trainSubmit(body, "timeseries");
      setLoadingApi(false);
      if (result?.code === "ERR_BAD_REQUEST") {
        setLoadingApi(false);
        toast.error(result.code, {
          position: "top-right",
        });
      }
      if (result?.data) {
        setLoadingApi(false);
        toast.success("Train model successfully", {
          position: "top-right",
        });
        setTrainModelData(result.data);
      } else {
        toast.error(result?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
    if (trainingValue.value === "anomaly_detection_models") {
      let body = {
        query: props.sqlQuery,
        table: "",
        features: dataSefFeatureValue,
        target: dataSefFeatureTarget ? dataSefFeatureTarget : [""],
        model: modelValue.value,
        hyperparams: hyperData ? hyperData : {},
        connection_name: props.selectedDB,
        subsample: trainedModal,
        subsampling_strategy: trainedModal
          ? samplingSteategyValue?.value
          : "None",
        subsample_ratio: trainedModal ? Number(samplingRatio) : 0,
        anomaly_detection_type: modelType,
      };
      setLoadingApi(true);
      let result = await trainSubmit(body, "anomaly_detection");
      setLoadingApi(false);
      if (result?.code === "ERR_BAD_REQUEST") {
        setLoadingApi(false);
        toast.error(result.code, {
          position: "top-right",
        });
      }
      if (result?.data) {
        setLoadingApi(false);
        toast.success("Train model successfully", {
          position: "top-right",
        });
        setTrainModelData(result.data);
      } else {
        toast.error(result.response.data.error, {
          position: "top-right",
        });
      }
    }
  };

  const viewTraind = async () => {
    try {
      let dataSefFeatureValue = dataSefFeature?.map((res) => res.value);
      let dataSefFeatureTarget = dataSetTarget?.map((res) => res.value);
      let body = {
        query: props.sqlQuery,
        connection_name: props.selectedDB,
        timeseries_type: timeSeriesValue.value,
        datetime_column: dateTimeValue.value,
        features:
          timeSeriesValue?.value === "multivariate" &&
          modelValue.value === "prophet"
            ? dataSefFeatureValue
            : null,
        target: dataSefFeatureTarget[0],
      };
      let result = await VIEWTRAINDS(body);
      console.log("SDDDDDD", result);
      if (result.data) {
        setViewDataChart(result?.data?.base64_image_string);
        setOpen(true);
      } else {
        toast.error(result.response.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {}
    // setOpen(true);
  };

  return (
    <>
      <div>
        <div className={classes.input}>
          <label>SQL query to fetch data</label>
          <textarea
            rows={"7"}
            value={props.sqlQuery}
            onChange={async (e) => {
              clearAll();
              props.sqlQueryFunc(e.target.value);
            }}
            placeholder="select * from table_name"
          ></textarea>
        </div>

        <Subsample
          data={props?.data?.subsampling_type}
          onChange={onChangeSamplingSteategy}
          samplingSteategyValue={samplingSteategyValue}
          trainedModal={trainedModal}
          onChangeTrainModal={onChangeTrainModal}
          samplingRatio={samplingRatio}
          query={props.sqlQuery}
          connection_name={props.selectedDB}
          onChangeSamplingPercentage={onChangeSamplingPercentage}
        />
        <div className={classes.btngroup}>
          <button
            className={props.sqlQuery === "" ? "disbledbtn" : "btn"}
            onClick={props.submitQuery}
          >
            Run query
          </button>
          <button
            className={props.sqlQuery === "" ? "disbledbtn" : "btn"}
            onClick={props.sampleData}
          >
            {!props.sampleDataView ? "View" : "Hide"} sample data
          </button>
          <button
            className={props.sqlQuery === "" ? "disbledbtn" : "btn"}
            onClick={props.generateEdaReport}
          >
            View EDA report
          </button>
        </div>
        {props.sampleDataView && <Q2MLDataTable dataTable={props?.dataTable} />}
      </div>
      {props?.data && props?.dataTable !== undefined && (
        <Box
          sx={{
            mt: 4,
          }}
        >
          <div className={classes.file}>
            <h1>Training AI Models</h1>
          </div>
          <div className={classes.grid2}>
            <DropDown
              onChange={onChangeTraining}
              data={props?.data?.mlmodel_list}
              title={"Training type"}
              value={trainingValue}
            />
            {trainingValue.value === "anomaly_detection_models" && (
              <DropDown
                data={[
                  { value: "supervised", label: "Supervised" },
                  { value: "unsupervised", label: "Unsupervised" },
                ]}
                title={"Training subtype"}
                onChange={onChangeModelType}
                value={modelTypeValue}
              />
            )}
            {trainingValue.value === "anomaly_detection_models" && <h1></h1>}
            <DropDown
              data={
                modelTypeValue?.value
                  ? supervisedAndUnsupervised(
                      props?.data?.[trainingValue?.value]?.model_list[
                        modelTypeValue?.value == "supervised" ? 0 : 1
                      ][
                        modelTypeValue?.value == "supervised"
                          ? ["supervised"]
                          : ["unsupervised"]
                      ]
                    )
                  : supervisedAndUnsupervised(
                      props?.data?.[trainingValue?.value]?.model_list
                    )
              }
              title={"Model list"}
              onChange={onChangeModelList}
              value={modelValue}
            />
            {trainingValue.value === "time_series_models" && (
              <DropDown
                data={supervisedAndUnsupervised(
                  props?.data?.[trainingValue?.value]?.timeseries_type
                )}
                title={"Timeseries type"}
                onChange={onChangeTimeSeriesList}
                value={timeSeriesValue}
              />
            )}
            {trainingValue.value === "time_series_models" && (
              <DropDown
                data={props.subParameter}
                title={"Date time coloumn"}
                onChange={onChangeDateTime}
                value={dateTimeValue}
              />
            )}
            {/* Timeseries prophet */}
            {modelValue.value === "prophet" && (
              <DropDown
                data={supervisedAndUnsupervised(
                  props?.data?.[trainingValue?.value]?.frequency_prophet
                )}
                title={"Frequency"}
                onChange={onChangeFrequency}
                value={frequencyValue}
              />
            )}
            {/* {JSON.stringify(
              props?.data?.[trainingValue?.value].frequency_statsmodel
            )} */}
            {modelValue.value === "statsmodels" && (
              <DropDown
                data={supervisedAndUnsupervised(
                  props?.data?.[trainingValue?.value]?.frequency_statsmodel
                )}
                title={"Frequency"}
                onChange={onChangeFrequency}
                value={frequencyValue}
              />
            )}
            {/* statsmode */}
            {modelValue.value === "prophet" && (
              <DropDown
                data={supervisedAndUnsupervised(
                  props?.data?.[trainingValue?.value]?.seasonality_type
                )}
                title={"Seasonality type"}
                onChange={onChangeSeasonilityType}
                value={seasonilityValue}
              />
            )}
            {modelValue.value === "prophet" && (
              <DropDown
                data={supervisedAndUnsupervised(
                  props?.data?.[trainingValue?.value]?.seasonality_mode
                )}
                title={"Seasonality mode"}
                onChange={onChangeSeasonilityMode}
                value={seasonilityMode}
              />
            )}{" "}
            {modelValue?.value && modelValue.value === "prophet" && (
              <Box>
                <label>
                  {
                    props?.data?.[trainingValue?.value]?.hyperparameter[0]
                      ?.hyperparameterlist?.label
                  }
                </label>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  min={
                    props?.data?.[trainingValue?.value]?.hyperparameter[0]
                      ?.Options[0]
                  }
                  max={
                    props?.data?.[trainingValue?.value]?.hyperparameter[0]
                      ?.Options[1]
                  }
                  onChange={(e) => {
                    setfourierSlider(e.target.value);
                  }}
                  valueLabelDisplay="auto"
                  step={
                    props?.data?.[trainingValue?.value]?.hyperparameter[0]
                      ?.Options[0] >= 1
                      ? 1
                      : 0.01
                  }
                />
              </Box>
            )}
            {modelValue.value !== "" && (
              <div>
                {modelValue?.value && (
                  <Subparameters
                    subParameter={props.subParameter}
                    onChangeTarget={onChangeTarget}
                    onChangeFeacture={onChangeFeacture}
                    satasModels={modelValue?.value}
                    showModels={trainingValue?.value}
                    timeSeriesValue={timeSeriesValue?.value}
                    modelType={modelType}
                  />
                )}
              </div>
            )}
            <div>
              {modelValue?.value && (
                <Hyperparameter
                  data={
                    props?.data?.[trainingValue?.value]?.[modelValue.value]
                      ?.hyperparameter
                  }
                  hyperData={changeHyperData}
                  showModels={trainingValue?.value}
                />
              )}
            </div>
          </div>
          {modelValue?.value && (
            <div className={classes.flex}>
              <button
                className={loadingApi ? "disbledbtn" : "btn"}
                type="button"
                onClick={trainModel}
              >
                Train Model
              </button>
              {trainingValue.value === "time_series_models" && (
                <button
                  className={loadingApi ? "disbledbtn" : "btn"}
                  type="button"
                  onClick={viewTraind}
                >
                  View trends
                </button>
              )}
            </div>
          )}
          {trainModelData && trainingValue?.value && (
            <div style={{ marginTop: "1rem" }}>
              <div>
                <div>
                  <h3>Metrics:</h3>
                  <ul>
                    {trainModelData &&
                      Object.entries(trainModelData?.metrics).map(
                        ([key, value]) => (
                          <li key={key}>
                            <strong>{key.replace(/_/g, " ")}:</strong> {value}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {}
        </Box>
      )}
      {loadingApi && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <DialogModal
        fullScreen={true}
        open={open}
        modalClose={modalClose}
        contentComponent={
          <TimeseriesChart
            modalClose={modalClose}
            viewDataChart={viewDataChart}
          />
        }
      />
    </>
  );
}

export default Q2mlDatasets;
