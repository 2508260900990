import React, { useState, useEffect } from "react";
import classes from "./q2ml.module.css";
import Q2mlSidebar from "./Q2mlSidebar";
import ExploreDatabase from "./ExploreDatabase";

import { ListAll } from "../../sevices";

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Component which renders the constant expanded chat bot box including the chat window and the input field & send button
*/
function Q2ml() {
  const [data, setData] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedDB, setSelectedDB] = React.useState();
  const [selectedDBID, setSelectedDBID] = React.useState();

  const handleListItemClick = (index, res) => {
    setSelectedIndex(index);
    setSelectedDB(res.db_name);
  };
  const getDatabaseData = async () => {
    try {
      let result = await ListAll(localStorage.getItem("userId"));
      let results = result?.data?.data?.processed_tasks?.databases;
      setSelectedDB(results[0].db_name);
      setSelectedDBID(results[0].db_info_id);
      setData(results ? results : []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      getDatabaseData();
    })();
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.height}>
        <div>
          <Q2mlSidebar
            data={data}
            selectedIndex={selectedIndex}
            handleListItemClick={handleListItemClick}
          />
        </div>
      </div>
      <div className={classes.chatMain}>
        <ExploreDatabase selectedDB={selectedDB} selectedDBID={selectedDBID} />
      </div>
    </div>
  );
}

export default Q2ml;
