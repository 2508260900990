import React, { useEffect } from "react";
import classes from "./q2ml.module.css";
import { Box, Slider } from "@mui/material";

function Hyperparameter(props) {
  const [data, setData] = React.useState(props?.data);
  useEffect(() => {
    if (props?.data) {
      const tempData = [...props?.data];
      tempData?.forEach((res, index) => {
        tempData[index].min = res.min;
        tempData[index].max = res.max;
      });
      props?.hyperData(tempData);
      setData(props?.data);
    } else {
      props?.hyperData();
      setData();
    }
  }, [props.data]);

  const handleChange = (newValue, index) => {
    const tempData = [...data];
    tempData[index].min = newValue[0];
    tempData[index].max = newValue[1];
    setData(tempData);
    props.hyperData(tempData);
  };

  return (
    <div>
      {props?.showModels !== "time_series_models" && (
        <div className={classes.file}>
          <h1>Hyperparameter</h1>
        </div>
      )}
      {data &&
        data?.map((res, index) => {
          res.new = {
            [res.hyperparameterlist.value]: [res.min, res.max],
          };

          return (
            <Box key={index}>
              <label>{res?.hyperparameterlist?.label}</label>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={[Number(res.min), Number(res.max)]}
                onChange={(e) => {
                  handleChange(e.target.value, index);
                }}
                valueLabelDisplay="auto"
                step={Number(res.Options[0]) >= 1 ? 1 : 0.001}
                min={Number(res.Options[0])}
                max={Number(res.Options[1])}
              />
            </Box>
          );
        })}
    </div>
  );
}

export default Hyperparameter;
