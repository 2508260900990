import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Q2mlHeader from "./Q2mlHeader";
import classes from "./q2ml.module.css";
import Q2mlDatasets from "./Q2mlDatasets";
import Q2mlInference from "./Q2mlInference";
import { submitQueryHelper } from "./helper";
import CustomTabPanel from "./CustomTabPanel";
import Q2mlDataPreview from "./Q2mlDataPreview";
import Q2mlTrainingLogs from "./Q2mlTrainingLogs";
import { GetModelList, ViewDataSet } from "../../sevices/q2mlservice";

function ExploreDatabase(props) {
  const [tableName, setTableName] = useState("");
  const [sqlQuery, setsqlQuery] = useState("");
  const [data, setData] = useState();
  const [dataTable, setDataTable] = useState();
  const [subParameter, setSubParameter] = useState();

  const [sampleDataView, setSampleDataView] = useState(false);
  const [dataPreviewData, setDataPreviewData] = useState([]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sampleData = () => {
    setSampleDataView(!sampleDataView);
  };

  // Select Table from dropdown dataset
  const selectTableName = async (e) => {
    setTableName(e.target.value);
    let result = await submitQueryHelper({
      query: `select column_name, data_type, character_maximum_length, is_nullable from information_schema.columns  where table_name = '${e.target.value}'`,
      connection_name: props.selectedDB,
    });
  };
  const sqlQueryFunc = (query) => {
    setsqlQuery(query);
  };

  // data preview
  // Set Data preview table Name
  const dataPreview = async () => {
    try {
      let result = await ViewDataSet({
        query:
          "select table_name from information_schema.tables where table_schema = 'public'",
        connection_name: props.selectedDB,
      });
      if (result) {
        setDataPreviewData(result?.data?.dataset);
      }
    } catch (error) {}
  };
  // select table_name from information_schema.tables where table_schema = 'public';
  // Click on run query to fetch all trainning list and view sample data
  const submitQuery = async () => {
    let body = {
      query: sqlQuery,
      connection_name: props.selectedDB,
    };
    try {
      let result2 = await ViewDataSet(body);
      let data = result2?.data?.columns_names?.map((res) => {
        return {
          value: res,
          label: res.toUpperCase(),
        };
      });
      if (result2?.data) {
        toast.success("Run query successfully", {
          position: "top-right",
        });
      }
      if (result2?.response?.data?.error) {
        toast.error(result2?.response?.data?.error, {
          position: "top-right",
        });
      }

      setDataTable(result2?.data);
      setSubParameter(data);
    } catch (error) {
      console.log("Error", error);
      toast.error("result2?.response?.data?.error", {
        position: "top-right",
      });
    }
  };

  const generateEdaReport = async () => {
    let body = {
      query: sqlQuery,
      connection_name: props.selectedDB,
    };

    const stateString = encodeURIComponent(JSON.stringify(body));
    const newTabUrl = `/q2ml/report?state=${stateString}`;
    window.open(newTabUrl, "_blank");
  };
  useEffect(() => {
    (async () => {
      let result = await GetModelList();
      if (result) {
        setData(result?.data);
      }
    })();
  }, []);

  return (
    <div className={classes.bg}>
      <Q2mlHeader handleChange={handleChange} value={value} />
      <CustomTabPanel value={value} index={0}>
        <Q2mlDatasets
          sqlQueryFunc={sqlQueryFunc}
          sqlQuery={sqlQuery}
          selectedDB={props.selectedDB}
          dataPreview={dataPreview}
          data={data}
          submitQuery={submitQuery}
          dataTable={dataTable}
          sampleData={sampleData}
          sampleDataView={sampleDataView}
          subParameter={subParameter}
          generateEdaReport={generateEdaReport}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Q2mlDataPreview selectedDBID={props.selectedDBID} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Q2mlTrainingLogs data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Q2mlInference
          data={data}
          feature={subParameter}
          sqlQuery={sqlQuery}
          selectedDB={props.selectedDB}
        />
      </CustomTabPanel>
    </div>
  );
}

export default ExploreDatabase;
