import * as React from "react";
import { Dialog, DialogContent } from "@mui/material";

export default function DialogModal(props) {
  return (
    <React.Fragment>
      <Dialog
        fullScreen={props.fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={props.open}
        onClose={props.modalClose}
      >
        <DialogContent>{props.contentComponent}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
