import React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { departmentList } from "../Utils";
import { MenuItem, TextField } from "@mui/material";

function FormInput(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { formik, para } = props;

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{ marginBottom: "1.2rem" }}
    >
      {!para.select && (
        <InputLabel htmlFor={para.label}>{para.label}</InputLabel>
      )}

      {para.select ? (
        <TextField
          sx={{ textAlign: "left" }}
          size="small"
          select
          // margin="normal"
          required
          fullWidth
          label={para.label}
          type="text"
          autoComplete={para.name}
          id={para.label}
          name={para.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[para.name] && Boolean(formik.errors[para.name])}
          helperText={formik.touched.department && formik.errors.department}
        >
          {departmentList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <OutlinedInput
          required={para.required}
          placeholder={`Enter Your ${para.label}`}
          autoComplete={para.name}
          id={para.name}
          label={para.label}
          type={
            para.password ? (showPassword ? "text" : "password") : "text"
            // showPassword ? "text" : "password"
          }
          name={para.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[para.name] && Boolean(formik.errors[para.name])}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {para.password ? (
                  showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )
                ) : (
                  <>{para.icon && <img src={para.icon} alt={para.name} />}</>
                  // <img src={para.icon} alt={para.name} />
                  // {para}
                  // <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      )}

      {!para.select && (
        <FormHelperText error id={para.name}>
          {formik.touched[para.name] && formik.errors[para.name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormInput;
