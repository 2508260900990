import React, { useState, useEffect } from "react";

import classes from "./q2ml.module.css";
import DropDown from "./DropDown";
import {
  DownloadInferenceData,
  GETInference,
  GETLogInference,
  GETLogs,
} from "../../sevices/q2mlservice";
import PieChart from "./PieChart";
import ScatorPlot from "./ScatorPlot";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function Q2mlInference(props) {
  const [linkUrl, setLinkUrl] = useState(false);
  const [base64Image, setbase64Image] = useState();
  const [dataGraph, setDataGraph] = useState();
  const [experimentData, setExperimentData] = useState();
  const [experimentValue, setExperimentValue] = useState();
  const [modelData, setModalData] = useState();
  const [modelValue, setModalValue] = useState();
  const [versionData, setVersionData] = useState();
  const [versionValue, setVersionValue] = useState();
  const [featureValue, setFeatureValue] = useState();
  const [featureData, setFeatureData] = useState();
  const [headers, setheaders] = useState();
  const [ndays, setNdays] = useState();
  const [trainingType, setTrainingType] = useState();
  const [trainingSubType, setTrainingSubType] = useState();
  const [sqlQuery, setSqlQuery] = useState();
  const [loadingApi, setLoadingApi] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const getLogs = async (trainingValue, trainingSubtype) => {
    let body = {
      training_type: trainingValue,
      sub_training_type: trainingSubtype,
    };
    try {
      setLoadingApi(true);
      let result = await GETLogs(body);

      const seen = new Set();
      let modalData = result?.data?.logs?.filter((res) => {
        const isDuplicate = seen.has(res["Experiment Name"]);
        seen.add(res["Experiment Name"]);
        return !isDuplicate;
      });
      let y = modalData.map((res) => {
        return { label: res["Experiment Name"], value: res["Experiment Name"] };
      });

      setExperimentData(y);
      setLoadingApi(false);
      setheaders(Object.keys(result?.data?.logs[0]));
    } catch (error) {
      setLoadingApi(false);
    }
  };
  const downloadResult = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Q2ML}/download_inference_data`,
        {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "output.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    // let result = await DownloadInferenceData();
    // console.log("rrrrrrrrrr", result.data);
    // Create an anchor element and set its href to the object URL
    // const link = document.createElement("a");
    // link.href = `${result.data}`;
    // link.download = "output.xlsx";
    // link.click();
    // const blob = await DownloadInferenceData();
    // console.log("bbbbbbbb", blob);
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.style.display = "none";
    // a.href = url;
    // a.download = "output.xlsx";
    // document.body.appendChild(a);
    // a.click();
    // // window.URL.revokeObjectURL(url);
  };
  const fetchResult = async () => {
    setDisabledBtn(false);
    if (trainingType?.value == "timeseries" && ndays == "") {
      toast.error("N days is required", {
        position: "top-right",
      });

      return false;
    }
    setLoadingApi(true);
    let dataSetFeatureValue = featureValue?.map((res) => res.value);
    let body = {
      experiment_name: experimentValue?.value,
      version: versionValue?.value,
      modelname: modelValue?.value,
      ndays: ndays ? Number(ndays) : null,
      user_features: dataSetFeatureValue,
      connection_name: props.selectedDB,
      query: sqlQuery,
      // sqlQuery,
    };
    try {
      let result = await GETInference(body);
      setDisabledBtn(true);
      setLinkUrl(result?.data?.file);
      setLoadingApi(false);
      if (trainingType.value == "classification") {
        let groups = {};

        result.data.forEach((num) => {
          if (!groups.hasOwnProperty(num)) {
            groups[num] = [];
          }
          groups[num].push(num);
        });
        setDataGraph(Object.values(groups));
      }
      if (trainingType.value == "regression") {
        setbase64Image(
          "data:image/jpg;base64," + result.data.base64_image_string
        );
      }
      if (trainingType.value == "timeseries") {
        setbase64Image(
          "data:image/jpg;base64," + result.data.base64_image_string
        );
      }
      if (trainingType.value === "anomalydetection") {
        setbase64Image(
          "data:image/jpg;base64," + result.data.base64_image_string
        );
        setDataGraph(result.data);
      }
    } catch (error) {
      setLoadingApi(false);
    }
  };

  const onChange = (newValue) => {
    setTrainingType(newValue);
    // setData();
    // Clear all state
    setbase64Image();
    setDisabledBtn(false);
    setDataGraph();
    setExperimentData();
    setExperimentValue();
    setModalData();
    setModalValue();
    setVersionData();
    setVersionValue();
    setFeatureValue();
    setFeatureData();
    setheaders();
    setNdays();
    setTrainingSubType();
    setSqlQuery();
    setLoadingApi(false);
  };

  const onChangeSubType = async (newValue) => {
    setTrainingSubType(newValue);
    // setData();
    setDisabledBtn(false);
    setLoadingApi(false);
    await getLogs(trainingType.value, newValue.value);
  };
  const onChangeExperiment = async (value) => {
    // Clear all state
    setbase64Image();
    setDataGraph();

    setModalData();
    setModalValue();
    setVersionData();
    setVersionValue();
    setFeatureValue();
    setFeatureData();
    setheaders();
    setNdays();
    setSqlQuery();
    setLoadingApi(false);
    setExperimentValue(value);
    setDisabledBtn(false);
    try {
      let body = {
        experiment_name: value.value,
        model_name: null,
        version: null,
      };
      let result = await GETLogInference(body);
      const seen = new Set();
      let modalData = result?.data?.logs?.filter((res) => {
        const isDuplicate = seen.has(res["Model Name"]);
        seen.add(res["Model Name"]);
        return !isDuplicate;
      });
      let y = modalData.map((res) => {
        return { label: res["Model Name"], value: res["Model Name"] };
      });

      setModalData(y);
    } catch (error) {}
  };

  const onChangeModel = async (value) => {
    setModalValue(value);
    try {
      let body = {
        experiment_name: experimentValue.value,
        model_name: value.value,
        version: null,
      };
      let result = await GETLogInference(body);
      let modalData = result.data.logs.map((res) => {
        return { label: res["Version"], value: res["Version"] };
      });
      setVersionData(modalData);
    } catch (error) {}
    setVersionValue(value);
  };
  const onChangeVersion = async (value) => {
    try {
      let body = {
        experiment_name: experimentValue.value,
        version: value.value,
        model_name: modelValue.value,
      };
      let result = await GETLogInference(body);
      setSqlQuery(result.data.logs[0].query);
      let modalData = result.data.logs[0].features.map((res) => {
        return { label: res, value: res };
      });
      setFeatureData(modalData);
      setFeatureValue(modalData);
    } catch (error) {}
    setVersionValue(value);
  };

  const onChangeFeature = (value) => setFeatureValue(value);

  const getLogsInference = async () => {
    try {
      let result = await GETLogInference();
    } catch (error) {}
  };

  return (
    <div>
      <div className={` ${classes.grid2}`}>
        <DropDown
          data={props?.data?.logs?.training_type}
          title={"Training type"}
          onChange={onChange}
        />
        {trainingType?.value === "classification" && (
          <DropDown
            data={props?.data?.logs?.classification_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "regression" && (
          <DropDown
            data={props?.data?.logs?.regression_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "timeseries" && (
          <DropDown
            data={props?.data?.logs?.timeseries_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "anomalydetection" && (
          <DropDown
            data={props?.data?.logs?.anomalydetection_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}

        {experimentData && (
          <DropDown
            data={experimentData}
            title={"Experiment name"}
            onChange={onChangeExperiment}
          />
        )}
        {modelData && (
          <DropDown data={modelData} title={"Model"} onChange={onChangeModel} />
        )}
        {versionData && (
          <DropDown
            data={versionData}
            title={"Version"}
            onChange={onChangeVersion}
          />
        )}
        {featureData && (
          <DropDown
            data={featureData}
            title={"Features"}
            onChange={onChangeFeature}
            isMulti={true}
            value={featureData}
            disabled={true}
          />
        )}
      </div>
      {trainingType?.value == "timeseries" && (
        <TextField
          sx={{ margin: "1rem 0", zIndex: "0" }}
          label="N Periods"
          size="small"
          placeholder="n periods"
          type="number"
          onChange={(e) => {
            setNdays(e.target.value);
          }}
        />
      )}

      {trainingSubType?.value !== "univariate" && sqlQuery && (
        <div>
          <div className={classes.input}>
            <label>SQL query </label>
            <textarea
              rows={"7"}
              value={sqlQuery}
              onChange={(e) => {
                setSqlQuery(e.target.value);
              }}
              placeholder={sqlQuery}
            ></textarea>
          </div>
        </div>
      )}

      <div className={classes.btngroup}>
        {trainingType?.value !== "classification" && (
          <button
            className={!disabledBtn ? "disbledbtn" : "btn"}
            type="button"
            onClick={downloadResult}
            style={{ marginBottom: "1rem" }}
          >
            Download result
          </button>
        )}
        <button
          className={loadingApi ? "disbledbtn" : "btn"}
          type="button"
          onClick={fetchResult}
          style={{ marginBottom: "1rem" }}
        >
          Fetch result {loadingApi && <div className="loader"></div>}
        </button>
      </div>
      {trainingType?.value === "classification" && (
        <div>
          <PieChart dataGraph={dataGraph} />
        </div>
      )}
      {trainingType?.value === "regression" && (
        <div>
          <ScatorPlot dataGraph={dataGraph} />
        </div>
      )}

      <div>
        <img src={base64Image} width={"100%"} />
      </div>

      {loadingApi && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default Q2mlInference;
