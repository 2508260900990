// Third Party
import React, { useState } from "react";
import { TextField, Typography, MenuItem, FormControl } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";

// Local functionality
import { dbList } from "../Utils";
import classes from "./Componet.module.css";
import { CreateDatabaseService, ProcessDatabaseService } from "../sevices";
import { toast } from "react-toastify";

// import FormInput from "./FormInput";
function DatabaseCreateForm(props) {
  const [loadingApi, setLoadingApi] = useState(false);
  const [dbValidation, setDbValidation] = useState(false);
  // For  Postgrace sql validation
  const validationSchema = yup.object({
    db_type: yup
      .string("Select your database type")
      .required("This field is required"),
    db_name: yup
      .string("Enter your name")
      .required("Database name  is required"),
    username: yup
      .string("Enter your username")
      .required("Database username  is required"),
    password: yup
      .string("Enter your password")
      .required("Password  is required"),
    db_host: yup
      .string("Enter your host name")
      .required("Host  name  is required"),
    db_port: yup.string("Enter your  port").required("Port  is required"),
  });
  // For Snowflake Validation schema
  const validationSchemaSnowFlake = yup.object({
    db_type: yup
      .string("Select your database type")
      .required("This field is required"),
    db_name: yup
      .string("Enter your name")
      .required("Database name  is required"),
    username: yup
      .string("Enter your username")
      .required("Database username  is required"),
    password: yup
      .string("Enter your password")
      .required("Password  is required"),
    warehouses: yup.string("Enter your  Warehouse"),
    // .required("Warehouse  is required"),
    schema: yup.string("Enter your  Schema"),
    // .required("Schema  is required"),
    role: yup.string("Enter your  Role"),
    // .required("Role  is required"),
    account_identifier: yup
      .string("Enter your  Account identifier")
      .required("Account identifier  is required"),
  });
  // let a = {
  //   db_type: "postgresql",
  //   db_credentials: {
  //     db_name: "postgres",
  //     username: "postgres",
  //     password: "postgres",
  //     db_host: "127.0.0.1",
  //     db_port: 5432,
  //   },
  //   user_id: "9eee14a7-4d36-4f3f-8694-c35756ac97db",
  // };

  // Creare instance of formik
  const formik = useFormik({
    // Initialize value
    initialValues: {
      db_type: "",
      db_name: "",
      username: "",
      password: "",
      db_host: "",
      db_port: "",
      user_id: "",
      account_identifier: "",
      warehouses: "",
      schema: "",
      role: "",
    },
    validationSchema: dbValidation
      ? validationSchemaSnowFlake
      : validationSchema,
    onSubmit: async (values) => {
      let snowflakeBody = {
        db_type: values.db_type,
        db_credentials: {
          db_name: values.db_name,
          username: values.username,
          password: values.password,
          account_identifier: values.account_identifier,
          warehouses: values.warehouses,
          schema: values.schema,
          role: values.role,
        },
        user_id: localStorage.getItem("userId"),
      };
      let body = {
        db_type: values.db_type,
        db_credentials: {
          db_name: values.db_name,
          username: values.username,
          password: values.password,
          db_host: values.db_host,
          db_port: values.db_port,
        },
        user_id: localStorage.getItem("userId"),
      };

      try {
        setLoadingApi(true);

        const result = await CreateDatabaseService(
          !dbValidation ? body : snowflakeBody
        );

        if (result.data.status === true) {
          const processDatabase = await ProcessDatabaseService({
            db_info_id: result.data.data.db_info_id,
            user_id: localStorage.getItem("userId"),
          });
          if (processDatabase.data.status) {
            toast.success(processDatabase.data.message);
            setLoadingApi(false);
          } else {
            setLoadingApi(false);
            toast.error(processDatabase.data.message);
          }
          setTimeout(() => {
            props.props.modalClose();
          }, 3000);
        } else {
          setLoadingApi(false);
          toast.error(result.data.message);
        }
      } catch (error) {
        setLoadingApi(false);
        console.log("errror", error);
      }
    },
  });

  return (
    <>
      {/* <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ display: "grid", marginTop: "2rem" }}
      >
        <FormInput
          formik={formik}
          para={{
            label: "Username",
            placeholder: "Enter Username",
            name: "email_id",
            required: true,
            icon: "userIcon",
          }}
        />
      </Box> */}
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.grid2}>
          <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Database Type
            </Typography>
            <TextField
              size="small"
              id="db_type"
              select
              label="Database type"
              name="db_type"
              value={formik.values.db_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.db_type && Boolean(formik.errors.db_type)}
              helperText={formik.touched.db_type && formik.errors.db_type}
            >
              {dbList.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={(e) => {
                    if (e.target.dataset.value === "snowflake") {
                      setDbValidation(true);
                    } else {
                      setDbValidation(false);
                    }
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Database Name
            </Typography>
            <TextField
              id="db_name"
              label="Database Name"
              variant="outlined"
              size="small"
              value={formik.values.db_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.db_name && Boolean(formik.errors.db_name)}
              helperText={formik.touched.db_name && formik.errors.db_name}
            />
          </FormControl>

          <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Database User Name
            </Typography>

            <TextField
              size="small"
              id="username"
              label="Database user name"
              variant="outlined"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </FormControl>
          <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Password
            </Typography>
            <TextField
              type="password"
              size="small"
              id="password"
              label="Password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </FormControl>

          {formik.values.db_type !== "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography
                variant="label"
                sx={{ margin: "0.5rem 0", fontSize: "14px" }}
              >
                Database Host
              </Typography>

              <TextField
                size="small"
                id="db_host"
                label="Host"
                variant="outlined"
                value={formik.values.db_host}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.db_host && Boolean(formik.errors.db_host)}
                helperText={formik.touched.db_host && formik.errors.db_host}
              />
            </FormControl>
          )}
          {formik.values.db_type !== "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography
                variant="label"
                sx={{ margin: "0.5rem 0", fontSize: "14px" }}
              >
                Database Port
              </Typography>
              <TextField
                id="db_port"
                label="Port"
                variant="outlined"
                size="small"
                value={formik.values.db_port}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.db_port && Boolean(formik.errors.db_port)}
                helperText={formik.touched.db_port && formik.errors.db_port}
              />
            </FormControl>
          )}
          {formik.values.db_type === "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography variant="label" sx={{ fontSize: "14px" }}>
                Warehouse
              </Typography>
              <TextField
                id="warehouses"
                label="Warehouses"
                variant="outlined"
                size="small"
                value={formik.values.warehouses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.warehouses && Boolean(formik.errors.warehouses)
                }
                helperText={
                  formik.touched.warehouses && formik.errors.warehouses
                }
              />
            </FormControl>
          )}
          {formik.values.db_type === "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography variant="label" sx={{ fontSize: "14px" }}>
                Schema
              </Typography>
              <TextField
                id="schema"
                label="schema"
                variant="outlined"
                size="small"
                value={formik.values.schema}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.schema && Boolean(formik.errors.schema)}
                helperText={formik.touched.schema && formik.errors.schema}
              />
            </FormControl>
          )}
          {formik.values.db_type === "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography variant="label" sx={{ fontSize: "14px" }}>
                Account identifier
              </Typography>
              <TextField
                id="account_identifier"
                label="account_identifier"
                variant="outlined"
                size="small"
                value={formik.values.account_identifier}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.account_identifier &&
                  Boolean(formik.errors.account_identifier)
                }
                helperText={
                  formik.touched.account_identifier &&
                  formik.errors.account_identifier
                }
              />
            </FormControl>
          )}

          {formik.values.db_type === "snowflake" && (
            <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
              <Typography variant="label" sx={{ fontSize: "14px" }}>
                Role
              </Typography>
              <TextField
                id="role"
                label="Role"
                variant="outlined"
                size="small"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
              />
            </FormControl>
          )}
        </div>
        <div className={classes.buttonDiv}>
          <button
            type="btn"
            className="btn"
            style={{ opacity: "0.4", pointerEvents: "none" }}
          >
            Check Connection
          </button>
          <button type="submit" className={loadingApi ? "disbledbtn" : "btn"}>
            Create Database {loadingApi && <div className="loader"></div>}
          </button>
          {props.props.q2ml !== true && (
            <button
              type="button"
              className="btn"
              onClick={props.props.modalClose}
            >
              Cancel
            </button>
          )}
        </div>
      </form>
      {/* <ToastContainer /> */}
    </>
  );
}

export default DatabaseCreateForm;
