import moment from "moment";
// import DatePicker from "react-datepicker";

export const getDates = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

export const formatDates = (value, format = "L") => {
  return moment(value).format(format);
};

export function parseJSONWithFallback(jsonString) {
  try {
    if (jsonString && jsonString !== null && jsonString !== undefined)
      return JSON.parse(jsonString);
  } catch (error) {
    return jsonString; // Return blank object
  }
}
export function formatTypeJson(y) {
  let regex = /'s(?![\w'])/g;
  let replacedString = y.replace(regex, "hello_test");
  let result = replacedString.replace(/'/g, '"');
  let result2 = result.replace(/hello_test/g, "'s");
  return result2;
  // return y;
}

// export const DatePickert = (props) => {
//   return (
//     <div id="range-date-event">
//       <DatePicker
//         dateFormat="MM/dd/yyyy"
//         selected={props.value}
//         onChange={(update) => {
//           props.handleDate(update);
//         }}
//         placeholderText={props.placeholder}
//         isClearable={true}
//       />
//     </div>
//   );
// };
