import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Q2mlHeader(props) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={props.value}
        onChange={props.handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Explore" {...a11yProps(0)} />
        <Tab
          label="Preview"
          {...a11yProps(1)}
          onClick={() => {
            // dataPreview();
          }}
        />
        <Tab
          label="History"
          {...a11yProps(2)}
          onClick={() => {
            // dataPreview();
          }}
        />
        <Tab
          label="Inference"
          {...a11yProps(3)}
          onClick={() => {
            // dataPreview();
          }}
        />
      </Tabs>
    </Box>
  );
}

export default Q2mlHeader;
