import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import classes from "../style.module.css";
import UpdateQueqe from "./UpdateQueqe";

/**
@author      : Pankaj Kumar
@date        : 2023-01-22
@description : Queqe page 
*/
export default function Queqe() {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const showModal = (title) => {
    handleClickOpen();
    setTitle(title);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.bg}>
        <Table sx={{ maxWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>List</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3, 4, 5].map((row, index) => (
              <TableRow
                index={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  List1
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => {
                      showModal("Details");
                    }}
                  >
                    {/* <CachedIcon /> */}
                    Details
                  </Button>
                  <Button>
                    Status
                    {/* <DetailsIcon color="green" /> */}
                  </Button>
                  <Button
                    onClick={() => {
                      showModal("Update");
                    }}
                  >
                    Update
                    {/* <ChatIcon color="blue" /> */}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateQueqe
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        title={title}
        // details={details}
        // update={update}
      />
    </>
  );
}
