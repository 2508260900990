import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./q2ml.module.css";

function TimeseriesChart(props) {
  const [base64Image, setbase64Image] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (props?.viewDataChart) {
          let result = props?.viewDataChart?.map((res) => {
            return "data:image/jpg;base64," + res;
          });
          setbase64Image(result);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.viewDataChart]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Time series plots</h2>

        <CloseIcon className={classes.cursor} onClick={props.modalClose} />
      </div>

      <div style={{ width: "80%", margin: "auto" }}>
        {base64Image?.map((res, index) => {
          return <img src={res} key={index} width={"100%"} />;
        })}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button type="button" className="btn" onClick={props.modalClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default TimeseriesChart;
