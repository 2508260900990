import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import File from "../module/file/File";
import Login from "../components/Login";
import Home from "../module/home/Home";
import Queqe from "../module/queqe/Queqe";
import Signup from "../components/Signup";
import NoMatch from "../components/NoMatch";
import PrivateRoutes from "./PrivateRoutes";
import Chatbot from "../module/chatbot/Chatbot";
import CreateFile from "../module/file/CreateFile";
import Metadata from "../module/metadata/Metadata";
import Database from "../module/database/Database";
import CreateDatabase from "../module/database/CreateDatabase";
import ForgotPassword from "../components/ForgotPassword";
import Q2ml from "../module/q2ml/Q2ml";
import Report from "../module/q2ml/Report";
function Router() {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index path="home" element={<Home />} />
            <Route index path="" element={<Home />} />
            <Route index path="file" element={<File />} />
            <Route path="create-file" element={<CreateFile />} />
            <Route path="metadata" element={<Metadata />} />
            <Route path="create-database" element={<CreateDatabase />} />
            <Route path="database" element={<Database />} />
            <Route path="queue" element={<Queqe />} />
            <Route path="chat/file/:id?" element={<Chatbot />} />
            <Route path="chat/database/:id?" element={<Chatbot />} />
            <Route path="q2ml" element={<Q2ml />} />
          </Route>
        </Route>

        <Route element={<Login />} path="/login" />
        <Route element={<Signup />} path="/sign-up" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route path="q2ml/report/:id?" element={<Report />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default Router;
