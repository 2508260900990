export const SIGN_UP = "user_signup/";
export const RESET_PASS = "forgot_password/";
export const SIGN_IN = "login/";

export const LIST_ALL = "list_user_processed_tasks/?user_id=";
export const LIST_ALL_USER_STATUS = "user_tasks_status/?user_id=";
export const QUESTION_ANSWERING = "question_answering/";
export const CHAT_HISTORY = "retrieve_chat_history/";
export const CREATEDATABASE = "create_connection/";
export const FILEUPLOAD = "upload_file/";
export const FOLDERUPLOAD = "upload_folder/";
export const PROCESSDATABASE = "process_database/";
export const SHAREDUSER = "shared_with_users/";
export const PROCESS_PDF_FILE = "process_pdf_file/";
export const PROCESS_FOLDER_PATH = "process_folder/";
export const PROCESS_DOCX_FILE = "process_docx_file/";
// folder_path;
export const PROCESS_TEXT_FILE = "process_text_file/";
export const PROCESS_CSV_FILE = "process_csv_file/";
export const PROCESS_EXCEL_FILE = "process_excel_file/";
export const GET_META_DATA = "get_metadata/";
export const UPDATE_META_DATA = "update_metadata/";
// Google Drive
export const GOOGLE_DRIVE_LIST = "gdrive_list_files";
export const GOOGLE_DRIVE_LIST_PROCESS = "process_gdrive_files";
// One Drive
export const ONE_DRIVE_LIST = "onedrive_list_files";
export const ONE_DRIVE_LIST_PROCESS = "process_onedrive_files";
export const JWTTOKEN = "q2ml_jwt_token/";
export const GETNOTES = "get_notes/";
export const ADDNOTES = "add_notes/";
export const DELETENOTES = "delete_notes/";
export const UPDATENOTES = "update_notes/";
