import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

function Q2MLDataTable(props) {
  return (
    <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={"small"}>
          <TableHead>
            <TableRow hover>
              {props?.dataTable?.columns_names?.map((res) => {
                return <TableCell align="left">{res}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.dataTable?.dataset.map((res, index) => {
              if (index < 10)
                return (
                  <TableRow key={index}>
                    {res.map((res2, index2) => {
                      return (
                        <TableCell key={index2} align="left">
                          {res2}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default Q2MLDataTable;
