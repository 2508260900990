import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { ReactComponent as DatabaseIcon } from "../../assets/DatabaseLined.svg";

export default function ListData(props) {
  // const navigate = useNavigate();
  // const location = useLocation();
  // let checkActive = location.pathname.split("/");
  // const [dataDatabase, setDataDatabase] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index, res) => {
    setSelectedIndex(index);
    props.setSelectedId(res);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List component="nav" aria-label="List " style={{ padding: 0 }}>
        {props?.dataDatabase?.length > 0 ? (
          props?.dataDatabase?.map((res, index) => {
            return (
              <ListItemButton
                key={index}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleListItemClick(event, index, res.db_info_id)
                }
              >
                <ListItemIcon>
                  <DatabaseIcon style={{ width: "15px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "100px",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                  primary={res.db_name}
                />
              </ListItemButton>
            );
          })
        ) : (
          <ListItemButton>
            <ListItemIcon>
              <DatabaseIcon style={{ width: "15px" }} />
            </ListItemIcon>
            <ListItemText
              sx={{
                width: "100px",
                overflow: "hidden",
                wordWrap: "break-word",
              }}
              primary={"No Database"}
            />
          </ListItemButton>
        )}
      </List>
    </Box>
  );
}
