import React, { useEffect, useState } from "react";
import classes from "./home.module.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as File } from "../../assets/File.svg";
import { ReactComponent as BulletPoint } from "../../assets/BulletPoint.svg";
import { ReactComponent as Database } from "../../assets/Database.svg";
import { jwtToken, RetrieveRecentLogs } from "../../sevices";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { FindInPage, Share, Storage, UploadFile } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import { GetRecentActivity } from "../../sevices/q2mlservice";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

function Home() {
  const navigate = useNavigate();
  let name = localStorage.getItem("userName");
  const [activityData, setActivityData] = useState(null);
  const [activityDataQ2ml, setActivityDataQ2ml] = useState(null);

  useEffect(() => {
    Q2mlToken();
    getRecentLogs();
    getRecentQ2ml();
  }, []);

  const Q2mlToken = async () => {
    let tokenApi = await jwtToken();
    localStorage.setItem("token-q2ml", tokenApi?.data?.access_token);
  };

  const getRecentLogs = async () => {
    try {
      const obj = { user_id: localStorage.getItem("userId") };
      const result = await RetrieveRecentLogs(obj);
      setActivityData(result.data.data);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getRecentQ2ml = async () => {
    try {
      const obj = { user_id: localStorage.getItem("userId") };
      const result = await GetRecentActivity(obj);
      setActivityDataQ2ml(result?.data?.logs);
    } catch (e) {
      console.log("error", e);
    }
  };

  const extractFileName = (input) => {
    // Split the input string into parts before and after the colon
    const parts = input.split(": ");

    // Check if we have exactly two parts and the second part is not empty
    if (parts.length === 2 && parts[1]) {
      // Split the path on each slash
      const pathParts = parts[1].split("/");

      // Extract the last part of the path, which is the file name
      const fileName = pathParts.pop();

      // Return the reassembled string with the file name only
      return parts[0] + ": " + fileName;
    }

    // Return the original input if it doesn't match the expected format
    return input;
  };

  return (
    <div className={classes.home}>
      <div className={classes.homemain}>
        <h2>Hello {name}! Welcome to DataLens.</h2>
        <h2>How can I help you?</h2>
        <div className={classes.mt2}>
          <div className={classes.list}>
            <BulletPoint />
            <label>
              Seamlessly retrieve information from diverse sources including
              TXT, PDF, CSV, DOCX, Excel, and databases in the form of chat
            </label>
          </div>
          <div className={classes.list}>
            <BulletPoint />
            <label>
              Ensure data security and integrity with access management
            </label>
          </div>
          <div className={classes.list}>
            <BulletPoint />
            <label>
              Extensible to support multiple Language Models (LLMs) and
              connectors, facilitating seamless integrations
            </label>
          </div>
          <div className={classes.list}>
            <BulletPoint />
            <label>
              Accelerated to support use cases like BI co-pilot, metadata
              creation, document search and various chatbots
            </label>
          </div>
          <div className={classes.list}>
            <BulletPoint />
            <label>On demand features and maintenance</label>
          </div>
        </div>
      </div>

      <div className={classes.homeBottom}>
        <div className={classes.homeright}>
          <div className={classes.mainBg}>
            <h4>Recent Activity</h4>
            <div className={classes.recentActivity}>
              {activityData == null && <p>No activities</p>}
              {activityData !== null && (
                <>
                  <List sx={{ width: "100%" }}>
                    {Object.keys(activityData).map((key, index) => (
                      <div key={index}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              {key === "upload_file" && <UploadFile />}
                              {key === "configured_db" && <Storage />}
                              {key === "queried_db" && <Storage />}
                              {key === "queried_file" && <FindInPage />}
                              {key === "meta_query" && <Storage />}
                              {key === "shared" && <Share />}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())}
                            // secondary={activityData[key]}
                            secondary={extractFileName(activityData[key])}
                          />
                        </ListItem>
                        {index !== Object.keys(activityData).length && (
                          <Divider variant="inset" component="li" />
                        )}
                      </div>
                    ))}
                  </List>

                  {activityDataQ2ml && (
                    <>
                      <List sx={{ margin: "0", padding: 0 }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>{<AutoGraphIcon />}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ textTransform: "capitalize" }}
                            primary={"Trained Model"}
                            secondary={`Trained model: ${activityDataQ2ml[0]?.training_type} - ${activityDataQ2ml[0]?.model_name}`}
                          />
                        </ListItem>
                      </List>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={classes.mainBg}>
            <h4>Quick action</h4>
            <div className={classes.homeupload}>
              <div
                className={classes.homebox}
                onClick={() => {
                  navigate("/file");
                }}
              >
                <div>
                  <File />
                </div>
                <span className={classes.homeclick}>Click here </span>
                <span className={classes.homeup}>to Upload Files</span>
                <span className={classes.homesupport}>
                  Supported Format: txt, csv,docs, pdf, xlsx
                </span>
              </div>
              <div
                className={classes.homebox}
                onClick={() => {
                  navigate("/database");
                }}
              >
                <div>
                  <Database />
                </div>

                <span className={classes.homeclick}>Click here </span>
                <span className={classes.homeup}> to Configure Database</span>
                <span className={classes.homesupport}>
                  Supported DB: postgresql, mysql
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
