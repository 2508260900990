import { useState, useEffect } from "react";
import CreateFile from "./CreateFile";
import classes from "./file.module.css";
import upload from "../../assets/Upload.svg";
import googleDrive from "../../assets/google-drive.svg";
import microsoft from "../../assets/microsoft.svg";

import DialogModal from "../../components/DialogModal";
import { ListAllUserStatus } from "../../sevices";
import { toast } from "react-toastify";
import CustomizedTables from "../../components/CustomizedTables";
import { useGoogleLogin } from "@react-oauth/google";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ListView from "./ListView";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Database Layout
*/

function File() {
  const scope = "https://www.googleapis.com/auth/drive";
  const [open, setOpen] = useState(false);
  const [openGoogle, setopenGoogle] = useState(false);
  const [data, setData] = useState([]);
  const [googleToken, setGoogleToken] = useState();
  const [microsoftToken, setMicrosoftToken] = useState();
  const [openMicrosoft, setOpenMicrosoft] = useState(false);

  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && inProgress === InteractionStatus.None && accounts.length > 0) {

      const tokenRequest = {
        scopes: ["User.Read", "Files.ReadWrite.All"],
        knownAuthorities: [],
        redirectUri: msalConfig.auth.redirectUri,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        clientId: msalConfig.auth.clientId,
        navigateToLoginRequestUrl: false,
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          setMicrosoftToken(response.accessToken);
        })
        .catch(async (e) => {
          throw e;
        });
    }
  }, [inProgress, accounts, instance, loading]);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  const modalGoogleOpen = () => {
    setopenGoogle(true);
  };

  const googleModalClose = () => {
    setopenGoogle(false);
  };

  const login = useGoogleLogin({
    scope: scope,
    onSuccess: (tokenResponse) => {
      setGoogleToken(tokenResponse.access_token);

      modalGoogleOpen(true);
      // Handle successful sign-in (e.g., store the access token)
    },
    onFailure: (error) => {
      console.error("Login Error:", error);
    },
  });

  const getFileData = async () => {
    try {
      let result = await ListAllUserStatus(localStorage.getItem("userId"));
      if (result.message) {
        toast.error(result.message, {
          position: "top-right",
        });
      }
      let results = result?.data?.data?.files;
      setData(results ? results : []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      getFileData();
    })();
  }, [open]);
  return (
    <div className={"m-2"}>
      <div className={classes.file}>
        <h1>File Manager</h1>
      </div>
      <div className="text-right ">
        <Tooltip title="Refresh">
          <IconButton onClick={() => getFileData()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <button className="btn btn-transparent" onClick={() => login()} title="Open Google Drive">
          {" "}
          <img className={classes.icon} src={googleDrive} alt="google-drive" />{" "}
        </button>
        <button
          className="btn btn-transparent"
          onClick={() => {
            instance.loginPopup().then(() => {
              setOpenMicrosoft(true);
            });
          }}
          title="Open One Drive"
        >
          {" "}
          <img className={classes.icon} src={microsoft} alt="one-drive" />{" "}
        </button>
        <button className="btn" onClick={modalOpen}>
          <img src={upload} title="upload" alt="upload" /> Upload Files or Folder
        </button>
      </div>
      {/* <ListComponent data={data} type={"file"} /> */}
      <CustomizedTables data={data} type={"file"} />
      <DialogModal open={open} modalClose={modalClose} contentComponent={<CreateFile modalClose={modalClose} />} />
      <DialogModal
        open={openGoogle}
        googleModalClose={googleModalClose}
        contentComponent={<ListView accountType="GOOGLE" token={googleToken} modalClose={googleModalClose} />}
      />
      <DialogModal
        open={openMicrosoft}
        modalClose={() => setOpenMicrosoft(false)}
        contentComponent={<ListView accountType="MICROSOFT" token={microsoftToken} modalClose={() => setOpenMicrosoft(false)} />}
      />
    </div>
  );
}

export default File;
