import axios from "axios";
import {
  CREATEDATABASE,
  LIST_ALL,
  SIGN_UP,
  SIGN_IN,
  // PROCESS_PDF_FILE,
  PROCESSDATABASE,
  FILEUPLOAD,
  GET_META_DATA,
  CHAT_HISTORY,
  SHAREDUSER,
  RESET_PASS,
  FOLDERUPLOAD,
  PROCESS_FOLDER_PATH,
  UPDATE_META_DATA,
  LIST_ALL_USER_STATUS,
  GOOGLE_DRIVE_LIST,
  GOOGLE_DRIVE_LIST_PROCESS,
  ONE_DRIVE_LIST,
  ONE_DRIVE_LIST_PROCESS,
  JWTTOKEN,
  DELETENOTES,
  ADDNOTES,
  GETNOTES,
  UPDATENOTES,
} from "../Utils/EndPointConst";
import axiosInstance from "./axiosInstanece";
// import   SIGN_UP

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json", // adjust content type as needed
};
const headerFile = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "multipart/form-data", // adjust content type as needed
};

export async function SignUp(body) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + SIGN_UP,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function SignIn(body) {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API + SIGN_IN,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function resetPassword(body) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + RESET_PASS,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}
export async function ListAll(user_id) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${LIST_ALL}${user_id}`,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function ListAllUserStatus(user_id) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${LIST_ALL_USER_STATUS}${user_id}`,
      headers,
    });

    return res;
  } catch (error) {
    console.log("errrrrrr", error);
    return error;
  }
}

export async function CreateDatabaseService(body) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: process.env.REACT_APP_API + CREATEDATABASE,
      data: body,
      headers,
    });
    return res;
  } catch (error) {
    return error;
  }
}

export async function ProcessDatabaseService(body) {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API + PROCESSDATABASE,
      body,
      headers
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetMetadataService(body) {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API + GET_META_DATA,
      body,
      headers
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function UpdateMetadataService(body) {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API + UPDATE_META_DATA,
      body,
      headers
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function ProcessPDF(body, path, folderpath = "") {
  if (path === `${process.env.REACT_APP_API}${PROCESS_FOLDER_PATH}`) {
    let a = {
      folder_path: `/home/ec2-user/metro/metro_v1/metro/${body.file_path}/${folderpath}`,
      // "data/user_1eea4fe9-60bf-43ea-befc-057d973a8c7f/pdf/Node+Core+Recap.pdf",
      user_id: body.user_id,
    };
    try {
      const response = await axiosInstance.post(path, a, headers);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  try {
    const response = await axiosInstance.post(path, body, headers);
    return response;
  } catch (error) {
    return error;
  }
}

export async function FileUploadService(body, documentFile) {
  try {
    let response = await axiosInstance({
      method: "post",
      url:
        documentFile === "zip"
          ? process.env.REACT_APP_API + FOLDERUPLOAD
          : process.env.REACT_APP_API + FILEUPLOAD,
      data: body,
      headerFile,
    });
    return response;
  } catch (error) {
    return error;
  }
}

// FILEUPLOAD;

export async function QuestionAnswer(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}question_answering/`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function ElasticSearch(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}question_answering_es/`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function ChatHistory(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${CHAT_HISTORY}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function SharedProfile(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${SHAREDUSER}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function QuestionAnswerMetaData(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}query_metadata/`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function RetrieveRecentLogs(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}retrieve_recent_logs/`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}
const newHeader = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json", // adjust content type as needed
  next_page_token: null,
};
export async function googleDriveList(requestBody, next_token) {
  try {
    let res = await axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_API}${GOOGLE_DRIVE_LIST}?access_token=${requestBody}`,
      header: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json", // adjust content type as needed
      },
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function googleDriveListNextPage(requestBody, next_token) {
  try {
    let res = await axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_API}${GOOGLE_DRIVE_LIST}?access_token=${requestBody}&page_token=${next_token}`,
      header: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json", // adjust content type as needed
      },
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function googleDriveListProcess(requestBody, token) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${GOOGLE_DRIVE_LIST_PROCESS}?access_token=${token}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function jwtToken(requestBody, userId) {
  try {
    let res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}${JWTTOKEN}`,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function getNotes(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${GETNOTES}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}
export async function addNote(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${ADDNOTES}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}
export async function deleteNotesApi(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${DELETENOTES}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function updateNotesApi(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${UPDATENOTES}`,
      data: requestBody,
      headers,
    });

    return res;
  } catch (error) {
    return error;
  }
}

// Get One Drive files List
export async function oneDriveList(requestBody) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${ONE_DRIVE_LIST}`,
      headers: {
        Authorization: requestBody,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
}

// Process One Drive Files
export async function oneDriveListProcess(requestBody, token) {
  try {
    let res = await axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_API}${ONE_DRIVE_LIST_PROCESS}`,
      data: requestBody,
      headers: {
        Authorization: token,
      },
    });

    return res;
  } catch (error) {
    return error;
  }
}
