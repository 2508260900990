import React from "react";

// Import local icon
import help from "../assets/help.svg";
import user_b from "../assets/user_b.svg";
import classes from "./layout.module.css";
// import datalens from "../assets/download.png";
import datalens from "../assets/datalens.svg";
import notification from "../assets/notification.svg";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const settings = ["Profile", "Logout"];

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Header page with the user information 
*/

function Header() {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const profile = (value) => {
    handleCloseUserMenu();
    localStorage.removeItem("userId");
    if (value === "Logout") navigate("/login");
  };
  return (
    <div className={classes.headerFlex}>
      <div>
        <img
          alt="home"
          className={classes.cursor}
          src={datalens}
          onClick={() => {
            navigate("/home");
          }}
        />
      </div>

      <div className={classes.headerFlexRight}>
        <span style={{ pointerEvents: "none" }}>
          <img alt="help" src={help} />
        </span>
        <span style={{ pointerEvents: "none" }}>
          <img alt="notification" src={notification} />
        </span>
        <span>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              <IconButton
                sx={{
                  // p: 0,
                  background: "#fff",
                  padding: "0.5rem",
                  borderRadius: "50%",
                }}
                onClick={handleOpenUserMenu}
              >
                <img alt="user" src={user_b} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "25px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    profile(setting);
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </span>
      </div>
    </div>
  );
}

export default Header;
