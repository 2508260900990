import React, { useState, useEffect } from "react";

import classes from "./q2ml.module.css";
import DropDown from "./DropDown";
import { GETLogs, modalDownLoad } from "../../sevices/q2mlservice";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

function Q2mlTrainingLogs(props) {
  const [data, setData] = useState();
  const [columns, setColumn] = useState();
  const [headers, setheaders] = useState();
  const [trainingType, setTrainingType] = useState();
  const [trainingSubType, setTrainingSubType] = useState();
  const [loadingApi, setLoadingApi] = useState(false);

  const handleDownload = async (row) => {
    let body = {
      experiment_name: row["Experiment Name"],
      version: row["Version"],
      modelname: row["Model Name"],
    };
    try {
      const result = await modalDownLoad(body);
      const imageUrl = result.data.file;
      if (result.data.file) {
        handleDownloada(result.data.file, row["Experiment Name"]);
      }
    } catch (error) {}
  };
  const handleDownloada = (base64String, fileName) => {
    // Decode the Base64 string to a byte array
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: "application/zip" });

    // Create an object URL from the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set its href to the object URL
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Programmatically click the anchor element to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the object URL and removing the link element
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const getLogs = async () => {
    let body = {
      training_type: trainingType?.value,
      sub_training_type: trainingSubType?.value,
    };
    try {
      setLoadingApi(true);
      let result = await GETLogs(body);
      if (result?.response?.data?.error) {
        toast.error(result.response.data.error, {
          position: "top-right",
        });
      }
      setData(result?.data?.logs);
      let col = Object.entries(result?.data?.logs[0]).map(([key, value]) => {
        return {
          field: key,
          headerName: key,
          width: 120,
          type: typeof value === "number" ? "number" : "string",
        };
      });
      col.push({
        field: "download",
        headerName: "Download",
        width: 150,
        renderCell: (params) => (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleDownload(params.row)}
          >
            Download
          </Button>
        ),
      });
      setColumn(col);

      setLoadingApi(false);
      setheaders(Object.keys(result?.data?.logs[0]));
    } catch (error) {
      setLoadingApi(false);
    }
  };

  const onChange = (newValue) => {
    setTrainingType(newValue);
    setData();
    setLoadingApi(false);
  };

  const onChangeSubType = (newValue) => {
    setTrainingSubType(newValue);
    setData();
    setLoadingApi(false);
  };

  return (
    <div className={classes.log}>
      <div className={`${classes.input} ${classes.grid2}`}>
        <DropDown
          data={props?.data?.logs?.training_type}
          title={"Training type"}
          onChange={onChange}
        />
        {trainingType?.value === "classification" && (
          <DropDown
            data={props.data.logs.classification_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "regression" && (
          <DropDown
            data={props.data.logs.regression_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "timeseries" && (
          <DropDown
            data={props.data.logs.timeseries_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
        {trainingType?.value === "anomalydetection" && (
          <DropDown
            data={props.data.logs.anomalydetection_sub_training_types}
            title={"Sub training type"}
            onChange={onChangeSubType}
          />
        )}
      </div>

      <div className={classes.btngroup}>
        <button
          className={loadingApi ? "disbledbtn" : "btn"}
          type="button"
          onClick={getLogs}
          style={{ marginBottom: "1rem" }}
        >
          Fetch result {loadingApi && <div className="loader"></div>}
        </button>
      </div>
      <div style={{ width: "100%", overflow: "auto" }}>
        {data && columns && (
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row["Experiment Name"] + row["Version"]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        )}
      </div>
    </div>
  );
}

export default Q2mlTrainingLogs;
