import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";

import { ReactComponent as DatabaseIcon } from "../assets/DatabaseLined.svg";
import { ReactComponent as FileIcon } from "../assets/FileLined.svg";
import { ListAll } from "../sevices";

export default function ListDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // let checkActive = location.pathname.split("/");
  const [dataFile, setDataFile] = useState([]);
  const [dataDatabase, setDataDatabase] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(() => {
    (async () => {
      try {
        let result = await ListAll(localStorage.getItem("userId"));
        let results = result?.data?.data?.processed_tasks?.files;
        let find = results?.findIndex((res) => {
          if (res.file_id === props.selectedID) return res;
          // return res;
        });

        let resultData = result?.data?.data?.processed_tasks?.databases;
        let find2 = resultData?.findIndex((res) => {
          if (res.db_info_id === props.selectedID) return res;
          // return res;
        });
        // if()
        let check = location.pathname.includes("/chatbot/database/");
        if (check) {
          setSelectedIndex(find2);
        } else {
          setSelectedIndex(find);
        }
        setDataFile(results ? results : []);
        setDataDatabase(resultData ? resultData : []);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleListItemClick = (event, index, res) => {
    props.clearChat();
    if (props.active === "file") navigate(`/chat/file/${res}`);
    else navigate(`/chat/database/${res}`);
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List component="nav" aria-label="List ">
        {props.active === "file"
          ? dataFile.map((res, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={(event) =>
                    handleListItemClick(event, index, res.file_id)
                  }
                >
                  <ListItemIcon>
                    {res.file_type === "FOLDER" ? (
                      <FolderIcon style={{ color: "var(--blue)" }} />
                    ) : (
                      <FileIcon style={{ width: "15px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      width: "100px",
                      overflow: "hidden",
                      wordWrap: "break-word",
                    }}
                    primary={res.file_name}
                  />
                </ListItemButton>
              );
            })
          : dataDatabase?.map((res, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={(event) =>
                    handleListItemClick(event, index, res.db_info_id)
                  }
                >
                  <ListItemIcon>
                    <DatabaseIcon style={{ width: "15px" }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      width: "100px",
                      overflow: "hidden",
                      wordWrap: "break-word",
                    }}
                    primary={res.db_name}
                  />
                </ListItemButton>
              );
            })}
      </List>
    </Box>
  );
}
