import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

export default function Q2mlSidebar(props) {
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List component="nav" aria-label="List" sx={{ padding: 0 }}>
        {props?.data?.map((res, index) => {
          return (
            <ListItemButton
              selected={index === props.selectedIndex}
              key={index}
              onClick={(event) => props.handleListItemClick(index, res)}
            >
              {/* <ListItemIcon>{res.icon}</ListItemIcon> */}
              <ListItemText
                sx={{
                  width: "100px",

                  overflow: "hidden",
                  wordWrap: "break-word",
                  "& .MuiListItemText .MuiTypography": {
                    fontSize: "12px",
                  },
                }}
                primary={res.db_name}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
