import React, { useEffect, useState } from "react";
import classes from "./q2ml.module.css";
import DropDown from "./DropDown";

function Subparameters(props) {
  const [dataFeature, setDataFeature] = React.useState();
  const [selectedValues1, setSelectedValues1] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState([]);

  const getFilteredOptions1 = () => {
    return dataFeature?.filter(
      (option) => !selectedValues2.includes(option.value)
    );
  };

  const getFilteredOptions2 = () => {
    return dataFeature?.filter(
      (option) => !selectedValues1.includes(option.value)
    );
  };

  const onChangeFeacture = (id) => {
    props.onChangeFeacture(id);
    const values = Array.from(id, (option) => option.value);
    setSelectedValues1(values);
  };

  const onChangeTarget = (id) => {
    props.onChangeTarget(id);
    const values = Array.from(id, (option) => option.value);
    setSelectedValues2(values);
  };
  useEffect(() => {
    setDataFeature(props.subParameter);
  }, [props.subParameter]);

  return (
    <div style={{ marginBottom: "1rem" }}>
      {props?.showModels !== "time_series_models" && (
        <div className={classes.file}>
          <h1>Subparameters</h1>
        </div>
      )}
      {props.timeSeriesValue !== "univariate" && (
        <DropDown
          title="Features"
          data={getFilteredOptions1()}
          isMulti={true}
          onChange={onChangeFeacture}
        />
      )}

      {props.modelType !== "unsupervised" && (
        <DropDown
          title="Target"
          onChange={onChangeTarget}
          data={getFilteredOptions2()}
          isMulti={true}
        />
      )}
    </div>
  );
}

export default Subparameters;
