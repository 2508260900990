import axios from "axios";

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from session storage
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // Dynamically set Content-Type to multipart/form-data if data is FormData
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses and errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Optionally handle specific errors, such as token expiration
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
      console.error("Unauthorized, logging out...");
      localStorage.removeItem("token");
      localStorage.removeItem("token-q2ml");
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
