import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GetMetadataService } from "../../sevices";
import { formatTypeJson, parseJSONWithFallback } from "../../Utils/Functions";

function Q2mlDataPreview(props) {
  const [activeTableData, setActiveTableData] = useState(true);
  const [tableIndex, setTableIndex] = React.useState();
  const [value, setValue] = React.useState("");
  const [tableDescription, setTableDescription] = useState();

  const handleDropdownChange = (event, index) => {
    const objIndex = tableDescription?.table_description?.tables.findIndex(
      (obj) => obj["table_name"] === event.target.value
    );
    setValue(event.target.value);
    setTableIndex(objIndex);
  };
  const getData = async (selectedId) => {
    try {
      if (selectedId) {
        let result = await GetMetadataService({
          db_info_id: selectedId,
          user_id: localStorage.getItem("userId"),
        });
        let results = result?.data?.data;

        let format_table_dis = formatTypeJson(results.table_description);
        let parse_table_dis = parseJSONWithFallback(format_table_dis);

        setTableDescription(parse_table_dis);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        if (props.selectedDBID) {
          await getData(props.selectedDBID);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.selectedDB]);

  return (
    <div>
      <div>
        {tableDescription?.table_description?.tables &&
        tableDescription?.table_description?.tables.length > 0 ? (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel>Select Table</InputLabel>
              <Select
                value={value}
                label="Select Table"
                onChange={handleDropdownChange}
              >
                {tableDescription?.table_description?.tables?.map(
                  (item, index) => (
                    <MenuItem key={item.table_name} value={item.table_name}>
                      {item.table_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Box>
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                  Table:
                </Typography>
                <Typography marginBottom={2}>
                  {
                    tableDescription?.table_description?.tables[tableIndex]
                      ?.table_name
                  }
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                  Description:
                </Typography>

                {activeTableData ? (
                  tableDescription?.table_description?.tables[tableIndex]
                    ?.description
                ) : (
                  <textarea
                    rows="7"
                    style={{ width: "100%" }}
                    defaultValue={
                      tableDescription?.table_description?.tables[tableIndex]
                        ?.description
                    }
                  ></textarea>
                )}
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Column Name
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableDescription?.table_description?.tables[
                      tableIndex
                    ]?.columns?.map((row, key) => (
                      <TableRow
                        key={row.column_name}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.column_name}
                        </TableCell>
                        <TableCell align="right">
                          {activeTableData ? (
                            row.description
                          ) : (
                            <textarea
                              style={{ width: "80%" }}
                              defaultValue={row.description}
                            ></textarea>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : (
          <>No data</>
        )}
      </div>
    </div>
  );
}

export default Q2mlDataPreview;
