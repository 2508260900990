// import React from 'react'

// function MetaDataChatbot() {
//   return (
//     <div>MetaDataChatbot</div>
//   )
// }

// export default MetaDataChatbot

import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";

import classes from "../chatbot/chatbot.module.css";
import { QuestionAnswerMetaData } from "../../sevices";
import { ReactComponent as SendS } from "../../assets/SendS.svg";
import { ReactComponent as UploadS } from "../../assets/UploadS.svg";
import { ReactComponent as UserICon } from "../../assets/Chatbot.svg";
import { ReactComponent as ChatbotR } from "../../assets/ChatbotR.svg";
import { getDates } from "../../Utils/Functions";
import { Switch } from "@mui/material";

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Component which renders the constant expanded chat bot box including the chat window and the input field & send button
*/
function MetaDataChatbot(props) {
  const param = useParams();
  const location = useLocation();

  const inputRef = useRef(null);
  const scrollBottom = useRef();
  const [fileType, setFileType] = useState(
    location?.pathname?.includes("file") ? "file" : "database"
  );
  const [active, setActive] = useState(
    location?.pathname?.includes("file") ? "file" : "database"
  );
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      question: "",
      answer: "How can I help you today?",
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // If the pressed key is "Enter", trigger a click event on the button
      inputRef.current.click();
    }
  };

  /**
   * The function `handleSendMessage` is used to send a message, update the message state, clear the
   * input field, set the typing indicator, and process the message using ChatGPT.
   */
  const handleSendMessage = async () => {
    setTyping(true);
    const newMessage = {
      question: inputMessage,
      answer: "Typing...",
      userSide: true,
    };
    setMessages([...messages, newMessage]);
    let obj = {
      db_info_id: props.selectedId,
      question: inputMessage,
      user_id: localStorage.getItem("userId"),
    };
    // const result = await QuestionAnswer(obj);
    try {
      const result = await QuestionAnswerMetaData(obj);
      if (result?.data?.status === false) {
        let copyInput = inputMessage;
        const newMessage = {
          question: copyInput,
          answer: "Error occurred in question-answering",
        };
        setMessages([...messages, newMessage]);
        setTyping(false);
        setInputMessage("");
      } else {
        if (inputMessage.trim() !== "") {
          const newMessage = {
            question: result?.data?.data?.question,
            answer: result?.data?.data?.answer?.answer,
          };
          /* update our message state with the updated value */
          setMessages([...messages, newMessage]);
          const newMessages = [...messages, newMessage];

          /* setting the value of the `inputMessage` state to an empty string to clear the input field */
          setInputMessage("");
          scrollBottom.scrollTop = scrollBottom.clientHeight + 20;
          setTyping(false);
          /* set the typing indicator */

          /* Call the processMessages function which does the API calling with the new messages as the parameter */
          // await processMessages(newMessages);
          // if (messageContainer) {
          //   messageContainer.scrollTop = messageContainer.scrollHeight + 20;
          // }
        }
      }
    } catch (error) {}
  };

  /**
   * The function `processMessages` takes an array of chat messages, converts them into API queries,
   * retrieves the last query, sends it to an API endpoint, and updates the chat messages state with
   * the response.
   * @param {Message[]} chatMessages - An array of objects representing chat messages. Each object has
   * the following properties:
   */

  function change(values) {
    let a = [];
    if (values) {
      for (const [key, value] of Object.entries(values)) {
        if (key) {
          const getvaluesFromObj = Object.values(value);
          a.push({ [key]: getvaluesFromObj });
        }
      }
    }
    return a;
  }

  useEffect(() => {
    // Check if the messageContainerRef exists
    if (scrollBottom.current && messages?.length > 0) {
      const lastMessage = scrollBottom.current;
      if (lastMessage && lastMessage.scrollIntoView) {
        // Scroll to the last message smoothly
        lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [messages]);
  useEffect(() => {
    let checkType = location.pathname.search("/chat/file/");
    if (checkType === -1) {
      setFileType("database");
    } else {
      setFileType("file");
    }
  }, [location, param, active, fileType]);

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages, props.selectedId]);

  return (
    <div>
      <div className={classes.chatMainMeta}>
        <div>
          <h2>{getDates(new Date())}</h2>

          <div className={classes.cc} ref={scrollBottom}>
            {messages?.map((res, index) => {
              if (res?.user && typing) {
                return (
                  <div className={classes.questionDiv}>
                    <label className={classes.question}>{res.question}</label>
                    <div className={classes.icon}>
                      <UserICon />
                    </div>
                  </div>
                );
              }
              return (
                <div key={index}>
                  <div className={classes.questionDiv}>
                    <label className={classes.question}>{res.question}</label>
                    <div className={classes.icon}>
                      <UserICon />
                    </div>
                  </div>
                  <div className={classes.answerDiv}>
                    <div className={classes.icon}>
                      <ChatbotR />
                    </div>
                    <label className={classes.answer}>
                      {typeof res.answer === "string"
                        ? res?.answer
                        : change(res?.answer).map((res) => {
                            return (
                              res &&
                              Object.entries(res).map(([key, value], index) => {
                                return (
                                  <table key={index} className={classes.table}>
                                    <tr>
                                      <th>{key}</th>
                                    </tr>
                                    {value &&
                                      value.length > 0 &&
                                      value.map((ressss, indexx) => {
                                        return (
                                          <tr>
                                            <td key={indexx}>{ressss}</td>
                                          </tr>
                                        );
                                      })}
                                  </table>
                                );
                              })
                            );
                          })}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={classes.chatFooter}
        // style={
        //   param.id ? { pointerEvents: "auto" } : { pointerEvents: "none" }
        // }
      >
        <div className={classes.chatSearch}>
          <input
            type="text"
            placeholder="Type your question here"
            onChange={(e) => {
              setInputMessage(e.target.value);
            }}
            value={inputMessage}
            onKeyPress={handleKeyPress}
          />
          <button
            ref={inputRef}
            onClick={handleSendMessage}
            className={classes.buttonSend}
            s
          >
            <SendS onClick={handleSendMessage} />
          </button>
        </div>
        {/* <div className={classes.chatFooterEnd}>
            <div>
              <label>Exact search</label>
              <Switch
                value={activeSearch}
                onChange={(e) => {
                  setActiveSearch(e.target.checked);
                }}
                defaultChecked
              />
              <label>AI search</label>
            </div>
          </div> */}
      </div>
    </div>
  );
}

export default MetaDataChatbot;
