import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

function PieChart(props) {
  const [option, setOption] = useState({});
  useEffect(() => {
    if (props?.dataGraph && props?.dataGraph?.length > 0) {
      setOption({
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            // avoidLabelOverlap: false,

            data: props?.dataGraph.map((res) => {
              return {
                value: res?.length,
                name: `Class ${res[0]} : ${res?.length} `,
              };
            }),
            radius: "50%",
            // label: {
            //   show: false,
            //   position: "center",
            // },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
          },
        ],
      });
    } else {
      setOption({});
    }
  }, [props]);

  return (
    <div>
      {
        <ReactECharts
          style={{ height: "500px" }}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
        />
      }
    </div>
  );
}

export default PieChart;
