import axios from "axios";

export async function GetConnection() {
  try {
    let res = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getconnections`,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function DownloadInferenceData() {
  try {
    let res = await fetch({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Ensure the response is treated as CSV
        // media_type='',
      },
      url: `${process.env.REACT_APP_Q2ML}/download_inference_data`,
    });

    return res.blob();
  } catch (error) {
    return error;
  }
}

export async function GetModelList() {
  try {
    let res = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getmodels`,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function GetRecentActivity() {
  try {
    let res = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/recentactivity`,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function ViewDataSet(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/show_dataset`,
      //   data: {
      //     query: "select * from  actor_info",
      //     connection_name: "postgreslocal_07-May-2024 16:17:00",
      //   },
      data: requestBody,
    });

    return res;
  } catch (error) {
    if (error?.response?.data?.error === "Signature has expired") {
      localStorage.clear();
      window.location.reload();
    }
    return error;
  }
}

export async function trainSubmit(requestBody, url) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/${url}`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function generateReport(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getedareport`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}
export async function GETLogs(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getlogs`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}
export async function GETInference(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/get_inference`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function GETSubSample(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/subsample_size`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function GETLogInference(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getlogsinference`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function VIEWTRAINDS(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/view_trends`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}

export async function modalDownLoad(requestBody) {
  try {
    let res = await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-q2ml")}`,
      },
      url: `${process.env.REACT_APP_Q2ML}/getmodel`,
      data: requestBody,
    });

    return res;
  } catch (error) {
    return error;
  }
}
