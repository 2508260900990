import { Typography } from "@mui/material";

// import Creatable, { useCreatable } from "react-select/creatable";
import CreatableSelect from "react-select/creatable";
import classesC from "./Componet.module.css";
import { useState } from "react";
import { SharedProfile } from "../sevices";
import { toast } from "react-toastify";

function ShareFile(props) {
  const [loadingApi, setLoadingApi] = useState(false);
  const [userList, setUserList] = useState([]);
  const handleSubmit = async () => {
    let selectList = userList.map((res) => {
      return res.value;
    });
    let body = {
      db_info_id: props.shareData.db_info_id
        ? props.shareData.db_info_id
        : null,
      file_id: props.shareData.file_id ? props.shareData.file_id : null,
      users_list: selectList,
      user_id: localStorage.getItem("userId"),
      department: "",
    };

    try {
      setLoadingApi(true);
      const result = await SharedProfile(body);

      toast.success(result.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoadingApi(false);
      props.modalClose();
    } catch (error) {
      setLoadingApi(false);
    }
  };

  return (
    <div>
      <form style={{ display: "grid", gap: "1rem" }}>
        <Typography variant="label">Enter Email</Typography>
        <CreatableSelect
          isMulti
          onChange={(res) => {
            setUserList(res);
          }}
        />

        <div className={classesC.buttonDiv}>
          <button
            type="button"
            className={loadingApi ? "disbledbtn" : "btn"}
            onClick={handleSubmit}
          >
            Share {loadingApi && <div className="loader"></div>}
          </button>
          <button type="button" className="btn" onClick={props.modalClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ShareFile;
