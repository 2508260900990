import React from "react";
import classes from "../style.module.css";
import DatabaseCreateForm from "../../components/DatabaseCreateForm";

/**
@author      : Pankaj Kumar
@date        : 2023-01-22
@description : Create Database 
*/

function CreateDatabase(props) {
  return (
    <div className={classes.bg}>
      {props.q2ml !== true && (
        <h2 className={classes.heading}>Configure Database</h2>
      )}
      <DatabaseCreateForm props={{ ...props }} />
    </div>
  );
}

export default CreateDatabase;
