import React from "react";
import classes from "./Componet.module.css";

function ChatButton(props) {
  return (
    <button
      className={classes.button}
      type={props.type}
      onClick={props.buttonClick}
    >
      {props.title}
    </button>
  );
}

export default ChatButton;
