import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import classes from "./layout.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { TextField } from "@mui/material";
import { addNote, deleteNotesApi, getNotes, updateNotesApi } from "../sevices";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
function Task(props) {
  const [data, setData] = useState([]);
  const [dataGet, setDataGet] = useState([]);
  const [count, setCount] = useState(0);
  const add = () => {
    setCount(count + 1);
    setData([...data, { id: count + 1, name: "" }]);
  };
  const saveNotes = async (id, res) => {
    let body = {
      user_id: localStorage.getItem("userId"),
      notebook_id: null,
      message: res.name,
    };
    if (!res.name) {
      toast.error("Notes are required.");
      return false;
    }

    try {
      const add = await addNote(body);
      toast.success("Notes saved successfully.");
      setData([]);
      await getFileData();
    } catch (error) {}
  };
  const deleteNotes = async (res) => {
    try {
      let body = {
        user_id: localStorage.getItem("userId"),
        notebook_id: res.notebook_id,
        message: res.name,
      };
      let result = await deleteNotesApi(body);
      toast.warning("Notes deleted successfully.");
      if (result) await getFileData();
    } catch (error) {}
    // setData(data.filter((item) => item.id !== id));
  };
  const deleteNotesLocal = async (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const updateItem = async (res) => {
    let body = {
      user_id: localStorage.getItem("userId"),
      notebook_id: res.notebook_id,
      message: res.name,
    };

    let result = await updateNotesApi(body);
    if (result) {
      toast.success("Notes updated successfully.");
      await getFileData();
    }
  };
  const getFileData = async () => {
    try {
      let body = {
        user_id: localStorage.getItem("userId"),
        notebook_id: null,
        message: null,
      };
      const result = await getNotes(body);
      if (result?.data?.data) {
        setDataGet(result?.data?.data);
      } else {
        setDataGet([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      getFileData();
    })();
  }, []);

  return (
    <div className={classes.task}>
      <button
        className={classes.fixedLayout2}
        type="button"
        onClick={props.openTask}
      >
        {!props.open ? (
          <ArrowBackIosIcon sx={{ fontSize: "15px", color: "#fff" }} />
        ) : (
          <ArrowForwardIosIcon sx={{ fontSize: "15px", color: "#fff" }} />
        )}
      </button>
      <div className={classes.addtask}>
        <span onClick={add}>
          <AddBoxIcon /> <span className={classes.pos12}>Add notes</span>
        </span>
      </div>
      {data &&
        data.map((res, index) => {
          return (
            <div className={classes.add} key={res.id}>
              <TextField
                id="outlined-multiline-flexible"
                label={`Add text `}
                multiline
                onChange={(e) => {
                  res.name = e.target.value;
                }}
              />
              <div className={classes.taskButton}>
                <button
                  onClick={() => {
                    saveNotes(res.id, res);
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    deleteNotesLocal(res.id);
                  }}
                  className={classes.danger}
                >
                  Discard
                </button>
                {/* <DoneIcon
                  sx={{ fontSize: "20px" }}
                  onClick={() => {
                    saveNotes(res.id, res);
                  }}
                />
                <ClearIcon
                  sx={{ color: "red", fontSize: "20px" }}
                  onClick={() => {
                    deleteNotesLocal(res.id);
                  }}
                /> */}
              </div>
            </div>
          );
        })}

      {dataGet &&
        dataGet.map((res, index) => {
          return (
            <div className={classes.add} key={res.notebook_id}>
              <TextField
                id="outlined-multiline-flexible"
                label={`Note ${index + 1}`}
                multiline
                defaultValue={res.message}
                // value={res.message}
                onChange={(e) => {
                  res.name = e.target.value;
                }}
              />

              <div className={classes.taskButton}>
                <button
                  onClick={() => {
                    updateItem(res);
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    deleteNotes(res);
                  }}
                  className={classes.danger}
                >
                  Discard
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Task;
