import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Local import
import classes from "../../components/Componet.module.css";
import metadataCss from "./metadata.module.css";
import {
  Box,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MetaDataChatbot from "./MetaDataChatbot";

import { ToastContainer, toast } from "react-toastify";
import { UpdateMetadataService } from "../../sevices";

/**
@author      : Pankaj Kumar
@date        : 2023-01-22
@description : Uddate metadate 
*/

function UpdateMetaData(props) {
  const [tableIndex, setTableIndex] = React.useState();
  const [tableRelationIndex, setTableRelationIndex] = React.useState();
  const [databaseDis, setDatabaseDis] = useState();

  // Table Discription
  const [activeTableDisc, setActiveTableDisc] = useState(false);
  const [activeTableData, setActiveTableData] = useState(true);
  const [activeTableRealtion, setActiveTableRelation] = useState(true);
  const [value, setValue] = React.useState("");
  const [relValue, setRelValue] = React.useState("");

  const [tables, setTables] = useState([]);

  const [showTableDetails, setShowTableDetails] = useState(false);
  const [relationshipTables, setRelationshipTables] = useState([]);
  const [showRelTableDetails, setShowRelTableDetails] = useState(false);
  const [localDiscription, setLocalDiscription] = useState(props.databaseDis);

  const handleDropdownChange = (event, index) => {
    const objIndex =
      props?.tableDescription?.table_description?.tables.findIndex(
        (obj) => obj["table_name"] === event.target.value
      );
    setValue(event.target.value);
    setTableIndex(objIndex);
    setShowTableDetails(!showRelTableDetails);
  };

  const handleRelDropdownChange = (event, index) => {
    let objRelationIndex =
      props?.tableDataR?.relationship_description?.tables.findIndex(
        (obj) => obj["name"] === event.target.value
      );

    setRelValue(event.target.value);
    setTableRelationIndex(objRelationIndex);
    setShowTableDetails(!showRelTableDetails);
    // setSelRelTable(obj);
    // setRelColumns(obj.relationships);
    setShowRelTableDetails(true);
    setActiveTableRelation(true);
  };

  const editTableDiscription = () => {
    setActiveTableDisc(true);
  };

  const editTableData = () => {
    setActiveTableData(false);
  };
  const saveTableData = async () => {
    let body = {
      db_info_id: props.selectedId,
      database_description: JSON.stringify(props.databaseDis),
      table_description: JSON.stringify({
        table_description: {
          tables: props?.tableDescription?.table_description.tables,
        },
      }),
      table_relation_description: JSON.stringify({
        relationship_description: {
          tables: props?.tableDataR?.relationship_description.tables,
        },
      }),
    };
    let bodyNormal = {
      db_info_id: props?.selectedId,
      database_description: props?.databaseDis,
      table_description: {
        table_description: {
          tables: props?.tableDescription?.table_description.tables,
        },
      },
      table_relation_description: {
        relationship_description: {
          tables: props?.tableDataR?.relationship_description.tables,
        },
      },
    };

    // return false;
    const result = await UpdateMetadataService(body);
    if (result.data.status) {
      window.location.reload();
      setActiveTableDisc(false);
      setActiveTableData(true);
      setActiveTableRelation(true);
      if (result.data.status) {
        props.updateAPI();
        window.location.reload();
      }
    }
  };
  const editTableRelation = () => {
    setActiveTableRelation(false);
  };

  useEffect(() => {
    setTableRelationIndex();
    setShowTableDetails(false);
    setShowRelTableDetails(false);
    setValue();
    setTableIndex();
    setRelValue();
    setActiveTableDisc(false);
  }, [props.selectedId, props.active]);

  return (
    <div className={classes.bg}>
      <div className={metadataCss.h40}>
        {props.active === "dd" && (
          <div>
            <h2 className={metadataCss.heading}>About</h2>
            {props.databaseDis?.database_description ? (
              <>
                <div className={metadataCss.save}>
                  <EditIcon onClick={editTableDiscription} />
                  <CheckCircleIcon onClick={saveTableData} />
                </div>
                <textarea
                  rows="15"
                  style={{ width: "100%" }}
                  value={props.databaseDis?.database_description}
                  disabled={!activeTableDisc}
                  onChange={(e) => {
                    props.updateDatabaseDis(e.target.value);
                  }}
                ></textarea>
              </>
            ) : (
              <>No data</>
            )}
          </div>
        )}

        {props.active === "td" && (
          <div>
            <h2 className={metadataCss.heading}>About</h2>

            {props?.tableDescription?.table_description?.tables &&
            props?.tableDescription?.table_description?.tables.length > 0 ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel>Select Table</InputLabel>
                  <Select
                    value={value}
                    label="Select Table"
                    onChange={handleDropdownChange}
                  >
                    {props?.tableDescription?.table_description?.tables?.map(
                      (item, index) => (
                        <MenuItem key={item.table_name} value={item.table_name}>
                          {item.table_name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                {showTableDetails && (
                  <Box>
                    <div className={metadataCss.save}>
                      <EditIcon
                        onClick={editTableData}
                        title="Edit description"
                      />
                      <CheckCircleIcon onClick={saveTableData} title="Save" />
                    </div>
                    <Box marginTop={2} marginBottom={2}>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Table:
                      </Typography>
                      <Typography marginBottom={2}>
                        {
                          props?.tableDescription?.table_description?.tables[
                            tableIndex
                          ]?.table_name
                        }
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Description:
                      </Typography>

                      {activeTableData ? (
                        props?.tableDescription?.table_description?.tables[
                          tableIndex
                        ]?.description
                      ) : (
                        <textarea
                          rows="7"
                          style={{ width: "100%" }}
                          defaultValue={
                            props?.tableDescription?.table_description?.tables[
                              tableIndex
                            ]?.description
                          }
                          onChange={(e) => {
                            props.tableDescription.table_description.tables[
                              tableIndex
                            ].description = e.target.value;
                          }}
                        ></textarea>
                      )}
                    </Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead className={metadataCss.heading}>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Column Name
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              Description
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props?.tableDescription?.table_description?.tables[
                            tableIndex
                          ]?.columns?.map((row, key) => (
                            <TableRow
                              key={row.column_name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.column_name}
                              </TableCell>
                              <TableCell align="right">
                                {activeTableData ? (
                                  row.description
                                ) : (
                                  <textarea
                                    style={{ width: "80%" }}
                                    defaultValue={row.description}
                                    onChange={(e) => {
                                      props.tableDescription.table_description.tables[
                                        tableIndex
                                      ].columns[key].description =
                                        e.target.value;
                                    }}
                                  ></textarea>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            ) : (
              <>
                No data
                {/* <textarea>HAHAH</textarea> */}
              </>
            )}
          </div>
        )}

        {props.active === "tr" && (
          <div>
            <h2 className={metadataCss.heading}>About</h2>

            {props?.tableDataR?.relationship_description?.tables ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel>Select Table</InputLabel>
                  <Select
                    value={relValue}
                    label="Select Table"
                    onChange={handleRelDropdownChange}
                  >
                    {props?.tableDataR?.relationship_description?.tables?.map(
                      (item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                {showRelTableDetails && (
                  <Box marginTop={2} marginBottom={2}>
                    <div className={metadataCss.save}>
                      <EditIcon onClick={editTableRelation} />
                      <CheckCircleIcon onClick={saveTableData} />
                    </div>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead className={metadataCss.heading}>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Table1
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Table2
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              Relationship
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              Description
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {props?.tableDataR?.relationship_description?.tables[
                            tableRelationIndex
                          ]?.relationships?.map((row, relIndex) => {
                            return (
                              <TableRow
                                key={relIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.table1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.table2}
                                </TableCell>
                                <TableCell align="right">
                                  {row.relationship}
                                </TableCell>
                                <TableCell align="right">
                                  {activeTableRealtion ? (
                                    row.description
                                  ) : (
                                    <textarea
                                      style={{ width: "80%" }}
                                      defaultValue={row.description}
                                      onChange={(e) => {
                                        if (
                                          props.tableDataR
                                            .relationship_description.tables[
                                            tableRelationIndex
                                          ]
                                        ) {
                                          props.tableDataR.relationship_description.tables[
                                            tableRelationIndex
                                          ].relationships[
                                            relIndex
                                          ].description = e.target.value;
                                        }
                                      }}
                                    ></textarea>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            ) : (
              <>No data</>
            )}
          </div>
        )}
        {props.active === "chat" && (
          <MetaDataChatbot
            selectedId={props.selectedId}
            messages={props.messages}
          />
        )}
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default React.memo(UpdateMetaData);
