import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import classesC from "../../components/Componet.module.css";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import classes from "../style.module.css";
import { FileUploadService, ProcessPDF } from "../../sevices";
import { toast } from "react-toastify";
import {
  PROCESS_CSV_FILE,
  PROCESS_DOCX_FILE,
  PROCESS_EXCEL_FILE,
  PROCESS_FOLDER_PATH,
  PROCESS_PDF_FILE,
  PROCESS_TEXT_FILE,
} from "../../Utils/EndPointConst";

function CreateFile(props) {
  const [documentType, setDocumentType] = useState("pdf");
  const [documentFile, setDocumentFile] = useState("");
  const [documentFileName, setDocumentFileName] = useState();
  const [fileValidate, setFileValidate] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);

  const fileValidateCheck = async () => {
    if (
      documentFileName &&
      documentType === "excel" &&
      (documentFileName.split(".").pop() === "xlsx" ||
        documentFileName.split(".").pop() === "xls")
    ) {
      return true;
    } else if (
      documentFileName &&
      documentType === "docx" &&
      (documentFileName.split(".").pop() === "docx" ||
        documentFileName.split(".").pop() === "doc")
    ) {
      return true;
    } else if (
      documentFileName &&
      documentFileName.split(".").pop() === documentType
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    let user_id = localStorage.getItem("userId");
    let url = "";
    if (documentFile.size > 60 * 1024 * 1024) {
      setFileValidate(true);
      setErrorMsg("File too large. Please upload a file smaller than 60 MB.");
      return false;
    }

    const chefile = await fileValidateCheck();
    if (!chefile) {
      setFileValidate(true);
      if (!documentFile) {
        setErrorMsg("Please upload a file or folder to proceed.");
        return false;
      }
      setErrorMsg(
        "Invalid file format. Please upload a file as selected in the dropdown."
      );
      return false;
    }

    setFileValidate(false);
    const formData = new FormData();
    formData.append("file", documentFile);
    formData.append("user_id", user_id);
    switch (documentType) {
      case "pdf":
        url = process.env.REACT_APP_API + PROCESS_PDF_FILE;
        break;
      case "csv":
        url = process.env.REACT_APP_API + PROCESS_CSV_FILE;
        break;
      case "txt":
        url = process.env.REACT_APP_API + PROCESS_TEXT_FILE;
        break;
      case "excel":
        url = process.env.REACT_APP_API + PROCESS_EXCEL_FILE;
        break;
      case "docx":
        url = process.env.REACT_APP_API + PROCESS_DOCX_FILE;
        break;

      case "zip":
        url = process.env.REACT_APP_API + PROCESS_FOLDER_PATH;
        break;

      // folder_path
      default:
        break;
    }
    let folderName = documentFileName.split(".zip")[0];

    try {
      setLoadingApi(true);
      const fileUpload = await FileUploadService(formData, documentType);

      if (fileUpload?.data?.data?.file_path) {
        let a = {
          file_path: fileUpload.data.data.file_path,
          user_id: user_id,
        };

        const result = await ProcessPDF(a, url, folderName);
        if (result.data.status === true) {
          setTimeout(() => {
            toast.success("File uploaded successfully", {
              position: "top-right",
            });

            setLoadingApi(false);
            props.modalClose();
            // navigate("/existing-file");
          }, 5000);
        } else {
          setLoadingApi(false);
          toast.error(result.data.message, {
            position: "top-right",
          });
        }
      } else {
        setLoadingApi(false);
        toast.error(fileUpload.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      setLoadingApi(false);
      console.log("Error", error);
    }
  };

  return (
    <div className={classes.bg}>
      <h2 className={classes.heading}>Upload File</h2>
      <form className={classes.grid2File}>
        <div>
          <FormControl fullWidth>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Select document format
            </Typography>
            <TextField
              sx={{ margin: "1rem 0" }}
              size="small"
              id="outlined-select-currency"
              select
              label="Select"
              value={documentType}
              defaultValue={documentType}
              onChange={(e) => {
                setDocumentType(e.target.value);
              }}
            >
              {[
                { value: "pdf", label: "PDF" },
                { value: "csv", label: "CSV" },
                { value: "txt", label: "TXT" },
                { value: "docx", label: "DOC" },
                { value: "excel", label: "EXCEL" },
                { value: "zip", label: "FOLDER" },
                // folder_path
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "1rem",
          }}
        >
          <FormControl fullWidth>
            <Typography
              variant="label"
              sx={{ margin: "0.5rem 0", fontSize: "14px" }}
            >
              Choose File/Folder
            </Typography>
            <label htmlFor="file" className={classes.inputFile}>
              <CloudUploadIcon /> Drag or Drop your File/Folder
              {/* <label>File name: {documentFileName}</label> */}
            </label>
            <input
              className={classes.none}
              // webkitdirectory="true"
              // mozdirectory="true"
              // multiple
              type="file"
              id="file"
              onChange={(e) => {
                setDocumentFile(e.target.files[0]);
                setDocumentFileName(e.target.files[0].name);
                setErrorMsg("");
                setLoadingApi(false);
              }}
            />
          </FormControl>

          {documentFile && <label>File name: {documentFileName}</label>}
          {fileValidate && <label style={{ color: "red" }}>{errorMsg}</label>}
        </div>
        <div className={classesC.buttonDiv}>
          <button
            type="button"
            className={loadingApi ? "disbledbtn" : "btn"}
            onClick={onSubmit}
          >
            Upload file or folder {loadingApi && <div className="loader"></div>}
          </button>
          <button type="button" className="btn" onClick={props.modalClose}>
            Cancel
          </button>
        </div>
      </form>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default CreateFile;
