import Select from "react-select";

function DropDown(props) {
  return (
    <div>
      <label>{props.title}</label>
      <Select
        label="Single select"
        onChange={props.onChange}
        options={props?.data}
        value={props.value}
        isMulti={props.isMulti}
      />
    </div>
  );
}

export default DropDown;
