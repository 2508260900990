import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import classes from "./layout.module.css";
import Sidebar from "./Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Task from "./Task";
/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Layout overall pages 
*/

export default function Layout() {
  const [open, setOpen] = React.useState(false);

  const openTask = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Header />
      <div className={classes.layout}>
        <Sidebar />
        <div>
          <Outlet />
        </div>
      </div>
      <button className={classes.fixedLayout} type="button" onClick={openTask}>
        {!open ? (
          <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
        ) : (
          <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
        )}
      </button>
      {open && <Task open={open} openTask={openTask} />}
    </div>
  );
}
