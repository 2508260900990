export const dbList = [
  { value: "mysql", label: "MySQL" },
  { value: "mongodb", label: "MongoDB" },
  { value: "postgresql", label: "Postgresql" },
  { value: "dyanamoDB", label: "DyanamoDB" },
  { value: "snowflake", label: "Snowflake" },
];

export const departmentList = [
  { value: "HR", label: "Human Resources" },
  { value: "AI", label: "Artificial Intelligence" },
  { value: "DP", label: "Data Product Engineering" },
  { value: "CE", label: "Cloud Data Engineering" },
];

export const q2mlModalList = [
  { value: "classification", label: "Classification" },
  { value: "regression", label: "Regression" },
  { value: "timeseries", label: "Timeseries" },
  { value: "forecasting", label: "Forecasting" },
  { value: "anomaly_detection", label: "Anomaly detection" },
];

export const q2mlModalRegression = [
  { value: "linear_regression", label: "Linear regression" },
  { value: "ridge_regressor", label: "Ridge regressor" },
  { value: "xgboost_regressor", label: "Xgboost regressor" },
  { value: "support_vactor_regressor", label: "Support vactor regressor" },
  { value: "random_forest_regressor", label: "Random forest regressor" },
];
