import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateReport } from "../../sevices/q2mlservice";

function Report(props) {
  const location = useLocation();
  const [html, setHTML] = useState({ __html: "" });

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(location.search);
      const stateString = query.get("state");
      if (stateString) {
        let body = JSON.parse(decodeURIComponent(stateString));
        let result = await generateReport(body);
        async function createMarkup() {
          return { __html: result?.data };
        }
        createMarkup().then((results) => setHTML(results));
        // setHTML(result?.data);
      }
    })();
  }, [location.search]);

  return (
    <div>
      <div dangerouslySetInnerHTML={html} />
    </div>
  );
}

export default Report;
